
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet, Link } from 'react-router-dom';

import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style

import Footer from "../archive/Footer";

// recent activity

import Exorcist from "../assets/2024/TheExorcist.jpg";
import ToyStory3 from "../assets/ToyStory3.jpg";
import PastLives from "../assets/PastLives.jpg"
import DreamScenario from "../assets/DreamScenario.jpg"
import WallE from "../assets/walle.jpeg";
import TheJoyLuckClub from "../assets/2024/TheJoyLuckClub.jpg"
import AFewGoodMen from "../assets/2024/AFewGoodMen.jpg"
import PoorThings from "../assets/2024/PoorThings.jpg"




// import dropdownnotes
import DropdownNotes from "./DropdownNotes";
// dash &#x23E4;








export default function DiaryRecents() {

  const recents = [
    {
      id: 1,
      title: 'Poor Things (2023)',
      image: PoorThings,
      date: "jun 2",
      rating: 4,
      link: null,
    },
    {
      id: 1,
      title: 'A Few Good Men (1992)',
      image: AFewGoodMen,
      quote: ['"you have the luxury of not knowing what i know"'],
      rating: null,
      link: null,

    },
    {
      id: 1,
      title: 'The Joy Luck Club (1993)',
      image: TheJoyLuckClub,
      rating: 3,
      link: null,
    },
    {
      id: 1,
      title: 'Wall-E (2008)',
      image: WallE,
      comment: ["i forgot to breath.", "i like the things & sounds."],
      rating: 5,
      link: "https://www.youtube.com/watch?v=0IPxIvbc_cs",
    },
    {
      id: 4,
      title: 'Dream Scenario (2023)',
      image: DreamScenario,
      quote: [`"you've been on my mind recently"`],
      comment: ["ari aster"],
      rating: 4,
      link: "https://www.youtube.com/watch?v=q3x9iUL-74w",
    },
    {
      id: 3,
      title: 'Past Lives (2023)',
      image: PastLives,
      note: ["i did not cry", "because i did not want to."],
      comment: ["the magic of celine song"],
      rating: 4.5,
      link: 'https://www.youtube.com/watch?v=VxcyuJuDmKI',
    },

    {
      id: 2,
      title: 'Toy Story 3 (2010)',
      image: ToyStory3,
      date: 'feb 6',
      comment: ["i almost cried."],
      rating: 3,
      link: null
    },
    {
      id: 1,
      title: 'The Exorcist (1973)',
      image: Exorcist,
      comment: ["rethinking the likeness of the conjuring universe"],
      date: 'feb 4',
      rating: 2.5,
      link: null,
    },

  ]



  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleButtonClick = componentName => {
    setSelectedComponent(componentName);

  };

  const getStars = (rating) => {
    if (rating === null || rating === undefined) {
      return (
        <>
          {'☆'.repeat(5)}
        </>
      );
    }

    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    return (
      <>
        {'★'.repeat(fullStars)}
        {halfStar && '½'}
      </>
    );
  };


  return (
    <>
      <div className="blog-page">

        {/* <div className="blog-page-title">
                    <h3>Movies</h3>
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>
                */}


        {/* ------------------------------------------------------ */}
        <div className="blog-page-title">
          <h4>Recent Activity</h4>

        </div>

        <div>
          {recents.map((movie) => (


            <div className="archive-list-container❤️" >

              <div key={movie.id}>
                <div className="movie-title-container">
                  <div className="left-side">

                    <a href={movie.link} target="_blank" rel="noopener noreferrer">
                      <img src={movie.image} style={{ aspectRatio: 183 / 275 }} width="70px" className="movie-images" alt={movie.title} />
                    </a>

                  </div>
                  <div className="right-side">
                    <p className=""><b>{movie.title}</b></p>
                    <div className="star-date">
                      <p className="right-side-movie-stars">{getStars(movie.rating)}</p>
                      <span>{movie.date ? `watched ${movie.date}` : ''}</span>
                    </div>
                    <div className="right-side-movie-quotes">
                      {Array.isArray(movie.quote) && movie.quote.map((q, index) => (
                        <div className="quote" key={index}>
                          <p>{q}</p>
                        </div>
                      ))}
                    </div>
                    {Array.isArray(movie.note) && movie.note.map((k, index) => (

                      <div className="right-side-movie-notes">
                        <p>{k}</p>
                      </div>

                    ))}


                  </div>

                </div>

                {Array.isArray(movie.comment) && movie.comment.map((p, index) => (

                  <div className="bottom-side-movie-comments">
                    <p>{p}</p>
                  </div>

                ))}
              </div>

            </div>
          ))}
        </div>


      </div>
      <br></br>
      <wbr></wbr>
      <div className="blog-page-the-end">
        <p><i>C'est Tout</i></p>
      </div>
      <Footer />
    </>
  )
}
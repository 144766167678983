import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Home';
import Archive from './Archive';

// 

// import React, {useState, useEffect} from 'react';
// import {Link} from 'react-router-dom';


export const NavBar = () => {
    return(



        /* // <Navbar expand='lg' className='bg-body-tertiary'> */
            <Navbar bf="light" data-bs-theme="light">
            <Container>
                <Navbar.Brand href="#">Andy Tran</Navbar.Brand>
    

                <Router>
                    <Routes>
                        <Route exact path='/Home' element={Home}/>
                        <Route path='/about' element={About}/>
                        <Route path='/archive' element={Archive}/>
                    </Routes>        
                </Router>
            </Container>
            </Navbar>
    )
}
export default NavBar;


import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import blog_image1 from "../assets/IMG_6658.jpg";
import blog_image2 from "../assets/cafe.jpg";
import blog_image3 from "../assets/apap.jpg";
import blog_image4 from "../assets/oceans.jpg";
import blog_image5 from "../assets/steak.jpg";
import blog_image6 from "../assets/hay.jpg";
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"
import BlogCarousel3 from "../components/BlogCarousel3.js"
import Footer from "../archive/Footer"

import BlogList from "../blogs/BlogList"




import blog_image9 from "../assets/pensacola.jpg";

export default function Paradise() {
    return (
        <>
        <div className="blog-page">
           <BlogList/>
                <div className="blog-page-title">
                    <h3>A Farewell to Pensacola / First Fifteen</h3>
                </div>
                <div className="blog-page-date">
                    <p>07 Jun 2023</p>
                </div>
                <div className="blog-page-my-words">
                    <p><i>"They are revving their engines for you"</i></p>
                    <p><i>- Dr. Ayana Johnson</i></p>
                </div>


                {/* ------------------------------------------------------ */}
                <div className="blog-page-title">
                    <h4>tl;dr:</h4>
                </div>
                <div className="blog-page-my-words">
                    <p>Phase 16 of the Full House Cinematic Unvierse. Happy birthday Vincent. Vermont, you are beautiful.</p>
                    <p>Siblings, I miss you. Thank you Kylie :'). Hello, Boulder</p>
        
                </div>

                {/* -------------------------------------------------- */}
                <div className="blog-page-title">
                    <h4>Pensacola:</h4>
                </div>
                <div className="blog-page-my-words">
                    <p>
                        Hey Tran Clan, mi roble, my sword & shield.
                        We did a lot this season- A lot of things that we ought to be used to by now, but every time, it reminds us of who we are. I guess I just wanted to make something out of the words stirring around our minds. - your brother, Andy.
                    </p>
                </div>

                {/* -------------------------------------------------------------- */}

                <div className="blog-page-images">
                    <img src={blog_image1} 
                    style={{aspectRatio: 4032/3024}} 
                    width= '550px' 
                    className="blog-page-images-theImage🐵"
                
                    />
                    <p className="figures">
                        Figure 1: Pensacola</p>
                </div>
                {/* -------------------------------------------------------------- */}
                
                <div className="blog-page-my-words">
                    <p>
                        Every once in a while, we'll return to the magic of Pensacola and fall under the spell of the seaborne breeze. We'll spend some time on the beach,
                        and somewhere in our minds, we'll take turns saying "this is our home" even though it's not really where we grew up.
            
            
                    We were raised in a (by definition) <i>sufficient</i> house (not shown above).
                        
                        
                        
                        {/* It's a strange place <i>*read Countdown to Oblivion (Butcher)</i>, but it's where we grew up. */}
                    </p>
                    <p>We spent a couple of nights remembering our pasts, sharing stories, and eating many yummy meals together.  The other chunk of our time was spent watching Jeopardy and catching up with some old souls.</p>
                    <p>Graduation day is here and for many, the enchanting task is to celebrate an incredible milestone for their children and friends, but for us, well... we're basically professionals. The greatest excitement is actually finding a row of seats that can accept us. We mourn until we get to play our well-trained version of Capture the Flag after the ceremony. Do you want to know the rules?
            
                        <ul>
                            <li>1.</li>
                            <li>2.</li>
                        </ul>
                    </p>
                    <p>Some elements of our time together: 
                        </p>
                </div>
                <BlogCarousel1/>
                <div className="blog-page-my-words">
                <p>
                        Anyways, cheers to us. This moment was going to greet us eventually. I'm forgetting what I wanted to say, but thank you Pensacola. We will be back hungry for your air.
                    </p>
                </div>
                <div className="blog-page-title">
                    <h4>Vermont:</h4>
                </div>
                
                <div className="blog-page-my-words">
                    <p>Phase Two: I wasn't planning on it, but thanks Keepie for flying me to Vermont.
                        We spent the first night together eating chicken wings. 
                        The next morning, I was able to connect with Pepper and Spear. 
                        Thanks Spear for taking us to your favorite spots. I hope you stay around for a long time. 
                        Oh— and welcome to the family :).
                    </p>
                    <p>Some moments that were captured:</p>
    
                </div>
                <BlogCarousel3/>
                <div className="blog-page-my-words"> 
                    <p>To note upon the bulk of the experience: Vermont was cold and I definitely underpacked. My first task was to find a jacket, but thankfully, the Sun was looking out for me. Thanks to the mints, we had some very nice guides to show us the beauties of Vermont. </p> 
                    <p>It was nice to see the Sultans again. Ice cream is good. Chocolate is okay. Farms are beautiful and full of history. Congratulations Pepper. Keepie & Gabe, thanks for the advice.</p>
                </div>
                <div className="blog-page-my-words">
                <p>More moments that were captured:</p>
                </div>
                <BlogCarousel2/>
                <div className="blog-page-my-words">
                    <p>Some thoughts I had during the trip:
                    <ul>
                            <li><something>1. Geronimo</something></li>
                            <li><something>2. Be brave, pineapple</something></li>
                            <li>3. "What does that even mean?"</li>
                            <li><a href="https://www.nobelprize.org/prizes/medicine/2017/press-release/">4. 2017 Nobel Prize (Circadian Rhythm in Gnats)</a></li>
                        </ul>
                    </p>
                </div>
                <div className="blog-page-title">
                    <h4>Conclusion:</h4>
                </div>
                <div className="blog-page-my-words">
                <p>If you are wondering what our "secret" formula is:
                <ul>
                            <li><something>1. Come have dinner with us. We would love your company.</something></li>
                            <li><something>2. "There is no secret ingredient" :surprisedPandaFace:</something></li>
                    </ul>
                    </p>
    
                    <p><i>Note: Why are people so obssessed with the truth? It sucks.</i></p>
                    <p>If you don't like option 2 and you want my truth now, I'm afraid you are stuck with option 1. We have a lot of words to say, but they will take a lifetime to write about.</p>

                    <p>L'Envoi: they are revving their engines for us. Let's go change the world.</p>
                </div>
                <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>

                
            <div>

            </div>
            <Footer/>
        </div>
        </>
    )
}
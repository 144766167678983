
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import wamblyImage from "../assets/edgedetect()"
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"
import Footer from "../archive/Footer"
import Barbie from "../assets/Barbie.jpg"
import Whale from "../assets/Whale.jpg"
import Person2Person from "../assets/Person2Person.jpg"
import AlwaysBeMyMaybe from "../assets/AlwaysBeMyMaybe.jpg"
import SmallSlowButSteady from "../assets/SmallSlowButSteady.jpg"
import Mid90s from "../assets/Mid90s.jpg"
import Beef from "../assets/Beef.jpg"
import BrideOfHalloween from "../assets/BrideOfHalloween.jpg"
import LittleWomen from "../assets/LittleWomen.jpg"
import TwentiethCenturyWomen from "../assets/20thCenturyWomen.jpg"












export default function Wambly() {
    return (
        <>
        <div className="blog-page">
            {/* <h1>Blog</h1> */}
            <h1>The Jungle</h1>
            <h2 className="latest-posts">Latest Posts</h2>
            <ul className='latest-posts-blog-links'>
                <li>
                    <Link className="blog-paradise" to="/Blog/Paradise">A Farewell to Pensacola / First Fifteen </Link>
                    <time className="timestamp"><p>07 Jun 2023</p></time>
                </li>
                <li>
                    <Link className="blog-wambly" to="/Blog/Wambly">Wambly</Link>
                    <time className="timestamp"><p></p></time>
                </li>
                <li>
                    <Link className="blog-travels" to="/Blog/Travels">Travels</Link>
                    <time className="timestamp"><p></p></time>
                </li>
            </ul>
                <div className="blog-page-title">
                    <h3>Wambly Space</h3>
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>
                <div className="blog-page-my-words">
                    <p><i>"Let's get lost" - wammis</i></p>
                </div>


                {/* ------------------------------------------------------ */}
                <div className="blog-page-title">
                    <h4>virtual notebook:</h4>
                </div>
               

                 <div className='blog-page-my-words'>
                {/* <p><i>3 aug 2023</i></p> */}
                <p><i>just a log of thoughts i've had. oh- and i ran out of paper.</i></p>
                 {/* <p><i>2 aug 2023</i></p> */}
                 <p>
                    Andy's Summmer Reading List:
                <ul>
                    <li>- Blue Hour (tiffany clarke harrison)</li>
                    <li>- Dictionary of Obscure Sorrows</li>
                    <li>- Maus </li>
                    <li>- Invisible Cities (calvino)</li>
                    <li>- The Wager (Grann)</li>
                    <li>- King: A life (eig)</li>
                    <li>- Hello Beautiful (ann napolitano)</li>
    
                </ul>
                </p>
                 <p>"I trust myself, just not with my own life" - amy [bazinga]</p>
                 <p>Everytime I think I have something figured out, there's an entire world of thoughts I haven't explored,
                    ways to think, and a new way to carry myself. I could have written the previous sentence when I was 16,
                    but even as I write it now, I still feel like I'm learning new ideologies that are changing my life. 
                    This- I find odd because of two things:
                </p>
                <p>
                    <ul>
                        <li><b>1. The inversion of this will be true.</b></li>
                        <li>- i.e. I will write the same thing in years time.</li>
                        <li><b>2. I would not have said number 1 when I was 16.</b></li>
                    </ul>
                </p>
                <p>
                    <ul>
                        <li>| older me, I hope you figure it out.</li>
                        <li>| little me, you too.</li>
                    </ul>
                </p>

                {/* <p>Interesting... I still repeat some of the same phrases in my head that remind me that "[I] am my own weapon."
                    I'm not really sure what difference exists between the version I am now and the warrior I was when I was a teenager.
                    I still have the same drive, the same passion to learn and change the world. 
                    The Earth is special- our ability to channel our energy as people and change it still rocks me to my core.
                </p> */}
                    

                <p>I don't really think boring people exist. It's just the truth. </p>
                <p>The truth sucks. We need more boring people in the world. I call first.</p>
            
                </div>
                <div 
                // className="carousel-lines"
                className="blog-page-my-words"
                >
                <ul>
                    <li><h3>Untitled</h3></li>
                    <li className="L1">Failing to void strays nightborne golds encouraged.</li>
                    <li className="L2">Searching sky not me, but another,</li>
                    <li className="L4">I stop, the soul and the silence still.</li>
                    <li className="L5"><br></br></li>

                </ul>
                 </div>
                 
                 <div className = "blog-page-my-words">

                <p>"what do you want to be when you grow up?" a person.</p>
                
                <p>"If you want to produce something good, you have to live a good life, or at least live a life." okay.</p>

                <p>Sometimes, I just want to rip open bad movies and point out interesting details. The hard part is finding someone who will watch them with you.
                </p>

                <p>
                    Next on the watchlist:
                <ul>
                    <li>- Working: What We Do All Day (Obama 23)</li>
                    <li>- C'mon C'mon (2021)</li>
                    <li>- Nine Days (2020)</li>
                    <li>- Fantastic Mr. Fox (2009)</li>
                    <li>- The Whale (2022)</li>
                    <li>- Columbus (2017)</li>
                </ul>
                </p>
            
                <p>"Be funny, comma, when you can, period!"</p>
                {/* <p>I love music or at least I love to listen to it. I spend a lot of time with people who have poured most of their energy into music and to say I love it
                    would only obfuscate their passion for it. I guess the physicist in me just loves things that oscillate. 
                    I play the piano, but I would not call myself a pianist. 
                    The human in me loves the idea of sharing stories through unique mediums. 
                </p> */}

                <p>I should pick up the guitar or play the drums. Maybe I'll learn something about myself or _someone_*.</p>
                <p>I feel like I said something wrong. I'm sorry. Let's have lunch someday.</p>

                <p>For me, it's frustrating that I cannot mechanically reproduce the sounds I hear in my head and the feelings attached to them.
                I do not think I am alone. I like music. I would not say I love it. It would only obfuscate the passion of the people around me who have dedicated a majority of their lives studying it. Thanks Danny for showing me your world.</p>
                <p>I love the passionate and I love to listen to them.</p>
                <p>The physicist in me loves things that oscillate. The human in me loves a good story.</p>
    
                <p>"you are in the right moment"</p>
               
                </div>
                <div className="archive-list-container❤️">
                    <div className ="movie-title-container">

                <div className = "left-side">
                    <img src={Barbie}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/>
                    
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>Barbie (2023)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"tell me your secret dream, child"</i></p>
                        <p><i>"close your eyes, now feel"</i></p>
                    </div>
        
                </div>
                
            </div>
            <div className="bottom-side-movie-comments">
                        <p>inspiring Little Women once again.</p>
                        <p>greta, you are a force.</p>
                        <p>also, what happened to kia?</p>
                    </div>
            </div>
          
            <div className="archive-list-container❤️">
                    <div className ="movie-title-container">

                <div className = "left-side">
                    <img src={Whale}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/>
                    
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>The Whale (2022)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"you."</i></p>
                        <p><i>"why?"</i></p>
                        <p><i>"because that's all I want to know about."</i></p>

                    </div>
                </div>
                </div>
                <div className="bottom-side-movie-comments">
                        <p>I should read moby dick.</p>
                        <p>hey uncle walt. nice to see you.</p>
                        <p>beep beep beep</p>
                    </div>
                </div>

            

           
            <div className="haiku">
               
                <p><i>This apartment smells</i></p>
                <p><i>This notebook is retarded</i></p>
                <p><i>I hate everyone.</i></p>
                <p><i> - sadie sink</i></p>


                
            </div>
    
          
            

                <div className = "archive-list-container❤️">
             <div className ="movie-title-container">
                <div className = "left-side">
                    <img src={Person2Person}style={{aspectRatio: 183/275}} width= "80px" className="movie-images"/>
                </div>
                <div className= "right-side">
                    <p className=""><b>Person to Person (2017)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"I know, and I love you for that"</i></p>
                    </div>
                    
                </div>
                </div>
                <div className="bottom-side-movie-comments">
                        <p>whoever wrote this should ride a bike in new york.</p>
                        <p>what a waste of Tavi Gevinson and Michael Cera.</p>
                    </div>
                {/* ------ */}
        
            </div>
            <div className="archive-list-container❤️">

            <div className ="movie-title-container">
                <div className = "left-side">
                    <img src={AlwaysBeMyMaybe}style={{aspectRatio: 183/275}} width= "80px" className="movie-images"/>
                </div>
                <div className= "right-side">
                    <p className=""><b>Always Be My Maybe (2019)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"I punched Keanu Reaves"</i></p>
                    </div>
                    
                </div>
                </div>
                <div className="bottom-side-movie-comments">
                        <p>I remember watching this back in '19.</p>
                        <p>a little cheesy, but bonus points for keanu reaves.</p>
                    </div>
                </div>

            <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                    <img src={SmallSlowButSteady}style={{aspectRatio: 183/275}} width= "80px" className="movie-images"/>
                </div>
                <div className= "right-side">
                    <p className=""><b>Small, Slow But Steady (2022)</b></p>
                    <p className="right-side-movie-stars">&#9734;&#9734;&#9734;&#9734;&#9734;</p>
                    <div className="right-side-movie-notes">
                        <p><i>show me what you got, miyake</i></p>
                    </div>
                    <div className="right-side-movie-comments">
                        <p>i'm <b>stoked</b>.</p>
                    </div>
                </div>
                </div>
            </div>

            <div className="blog-page-my-words">
                <p>so this accidentally became a letterboxd. coming soon...</p>
            </div>
            <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                    <img src={Mid90s}style={{aspectRatio: 183/275}} width= "80px" className="movie-images"/>
                </div>
                <div className= "right-side">
                    <p className=""><b>mid90s (2018)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"just say nothing"</i></p>
                        <p><i>"thank you"</i></p>

                    </div>
                    
                </div>
                </div>
                <div className="bottom-side-movie-comments">
                        <p>every kid needs a ray in their life.</p>
                    </div>
            </div>
            <div className="blog-page-my-words">
                <p>Jonah Hill versus A24. 1-0.</p>
                <p><a href="https://www.oc.edu/news/alumni-having-a-ball-with-star-wars-work">https://www.oc.edu/news/alumni-having-a-ball-with-star-wars-work</a></p>
                <p>Goodbye, Boulder. I will miss you.</p>
                <p>Hello San Diego. Visalia, you are beautiful. Sequoias are inspiring. Santa Monica is interesting. This was a fun weekend with my family. Thanks Mints for showing us your side of the world. Incredible food & Funny moments.</p>
                <p>Watches with my family:
                    <ul>
                        <li>Lincoln Lawyer (Netflix 22-)</li>
                        <li>Roman Empire (Netflix 16-19)</li>
                        <li>The Gladiator (2000)</li>
                        <li>Little Women (2022)</li>
                    </ul>
                </p>
            </div>
            <div className="archive-list-container❤️">
                    <div className ="movie-title-container">

                <div className = "left-side">
                    <img src={Beef}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/>
                    
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>Beef (2023)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"i see your life"</i></p>
                        <p><i>"you poor thing"</i></p>
                        <p><i>"i see it all"</i></p>
                    </div>
                </div>
                
            </div>
            <div className="bottom-side-movie-comments">
                <p>something of the caliber of ali wong</p>
            </div>
            </div>
            <div className="archive-list-container❤️">
                    <div className ="movie-title-container">

                <div className = "left-side">
                    <img src={BrideOfHalloween}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/>
                    
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>Detective Conan: The Bride of Halloween (2022)</b></p>
                    <p className="right-side-movie-stars">&#9734;&#xBD;</p>
                    <div className="right-side-movie-notes">
                        <p><i>proceed with caution</i></p>
                    </div>
                </div>
                
            </div>
            </div>
            <div className="archive-list-container❤️">
                    <div className ="movie-title-container">

                <div className = "left-side">
                    <img src={LittleWomen}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/>
                    
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>Little Women (2022)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-notes">
                    <p>stunned by Kim Go-eun</p>
                    </div>
                </div>
                
            </div>
            <div className="bottom-side-movie-comments">
            <p>this was a delightful watch for me and my family.</p>
            <p>every scene conjured by a magical group of people.</p>
            </div>
            </div>

            <div className="archive-list-container❤️">
                    <div className ="movie-title-container">

                <div className = "left-side">
                    <img src={TwentiethCenturyWomen}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/>
                    
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>20th Century Women (2016)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                    <p><i>"he could write with his left hand"</i></p>
                    <p><i>"you got birds?"</i></p>
                    </div>
                </div>
                
            </div>
            <div className="bottom-side-movie-comments">
                <p>again- greta, you are a force.</p>
            
            </div>
            </div>
            
            <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>
               
               
                </div>
                

{/*                
                <div className="blog-page-title">
                    <h4></h4>
                </div>
                <div className="blog-page-my-words">
                    <p>
                        EdgeDetect[]
                    </p>
                </div>
                <div className="blog-page-images">
                    <img src={wamblyImage} 
                    style={{aspectRatio: 642/388}} 
                    width= '550px' 
                    className="blog-page-images-theImage"
                
                    />
                    <p className="figures">
                        Figure 1: EdgeDetect[]</p>
                </div> */}
                

        <Footer/>
        </>
    )
}

import React, {useEffect, useRef} from 'react';
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import wamblyImage from "../assets/edgedetect()"

import LD from "../components/LD"

import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"

import Footer from "../archive/Footer"

// List of 2023
import Spider from "../assets/AcrossTheSpiderVerse.jpg"
import Barbie from "../assets/Barbie.jpg"
import Whale from "../assets/Whale.jpg"
import Person2Person from "../assets/Person2Person.jpg"
import AlwaysBeMyMaybe from "../assets/AlwaysBeMyMaybe.jpg"
import SmallSlowButSteady from "../assets/SmallSlowButSteady.jpg"
import Mid90s from "../assets/Mid90s.jpg"
import Beef from "../assets/Beef.jpg"
import BrideOfHalloween from "../assets/BrideOfHalloween.jpg"
import LittleWomen from "../assets/LittleWomen.jpg"
import TwentiethCenturyWomen from "../assets/20thCenturyWomen.jpg"
import DawnOfThePlanetOfTheApes from "../assets/DawnOfThePlanetOfTheApes.jpg"
import DarkKnight from "../assets/DarkKnight.jpg"
import TheMonkeyKing from "../assets/TheMonkeyKing.jpg"
import GretaLittleWomen from "../assets/GretaLittleWomen.jpg"
import BulletTrain from "../assets/BulletTrain.jpg"
import OceansEight from"../assets/OceansEight.jpg"
import Wolf from"../assets/WolfOfWallStreet.jpg"
import LadyBird from "../assets/LadyBird.jpg"
import Hereditary from "../assets/Hereditary.jpg"
import PrincessKaguya from "../assets/PrincessKaguya.jpg"
import Cloudy2 from "../assets/Cloudy2.jpg"
import MoM from "../assets/MemoriesOfMurder.jpg"
import MM from "../assets/MurderMystery.jpg"
import MM2 from "../assets/MurderMystery2.jpg"
import CenturyGirl from "../assets/20thCenturyGirl.jpg"
import UsAndThem from "../assets/UsAndThem.jpg"
import Minari from "../assets/Minari.jpg"
import WillyWonka from "../assets/WillyWonka.jpg"
import Okja from "../assets/Okja.jpg"
import ODD from "../assets/OperationDumboDrop.jpg"


// The List :)
import TenThingsIHate from "../assets/Poems/10Things.pdf"
import FiveFeetApart from "../assets/FiveFeetApart.jpg"
import HTTYD from "../assets/HTTYD.jpg"
import HTTYD2 from "../assets/HTTYD2.jpg"
import Bernadette from "../assets/Bernadette.jpg"
import TenThings from "../assets/10Things.jpg"
import threehundred from "../assets/300.jpg"
import Ratatouille from "../assets/Ratatouille.jpeg"
import Rango from "../assets/Rango.jpg"
import NineDays from "../assets/NineDays.jpg"
import Wonderland from "../assets/AliceInWonderland.jpg"
import HighAndLow from "../assets/High&Low.jpg"
import RushHour from "../assets/RushHour.jpg"
import Gamblers from "../assets/Gamblers.jpg"
import PastLives from "../assets/PastLives.jpg"


// Oldies !!!
import SurfNinjas from "../assets/SurfNinjas.jpg"
import ThreeNinjas from "../assets/ThreeNinjas.jpg"
import LittleRascals from "../assets/LittleRascals.jpg"
import PitchPerfect from "../assets/PitchPerfect.jpg"
// ten things


// horror thiller mystery
import TheConjuring from "../assets/TheConjuring.jpg"
import TheConjuring2 from "../assets/TheConjuring2.jpg"
import TheNun from "../assets/TheNun.jpg"
import AnabelleCreation from "../assets/AnabelleCreation.jpg"
import Annabelle from "../assets/Annabelle.jpg"
import TheNun2 from "../assets/TheNun2.jpg"
import WorldFair from "../assets/WorldFair.jpg"
import LaLlorona from "../assets/LaLlorona.jpg"



// Testing Videos
import Videos from "../archive/Videos"

// List of Blogs
import BlogList from "../blogs/BlogList"


// Logos
import NG from "../assets/NG/northrop-grumman-logo-2.png"



// Dropdown 
import Dropdown from "./Dropdown";
import DropdownNotes from "./DropdownNotes";


// dash &#x23E4;






export default function Watches() {
    useEffect(() => {
        const handleButtonClick = event => {
          const clickedButton = event.target;
    
          if (!clickedButton.closest('.seasons')) {
            return; // Clicked outside the .seasons div
          }
    
          const buttons = document.querySelectorAll('.seasons button');
    
          buttons.forEach(button => {
            if (button !== clickedButton) {
              button.classList.remove('active');
            }
          });
    
          clickedButton.classList.add('active');
        };
    
        document.addEventListener('click', handleButtonClick);
    
        return () => {
          document.removeEventListener('click', handleButtonClick);
        };
      }, []);
    
    return (
        <>
        <div className="blog-page">
           
                {/* <div className="blog-page-title">
                    <h3>Movies</h3>
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div> */}
             
               
                


                {/* ------------------------------------------------------ */}
                <div className="blog-page-title">
                    <h4>Lists</h4>
            
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>
                
            
                
          
            <Dropdown buttonContent={<p className="movie-folder">watchlist</p>}>
                <div className="movie-tabs">
                    <h4>what's next</h4>
                </div>
            <div className="blog-page-my-words">
                {/* <p>stuff I want to watch</p>
                 */}
                <p>
                <ul>
                    <li>- Evil Does Not Exist (Hamaguchi 2023)</li>
                    <li><span>- <s>Dream Scenario (Borgli 2023)</s></span></li>
                    <li>- Wonka (King 2023)</li>
                    <li><span>- <s>Past Lives (Song 2023)</s></span></li>  
                    <li>- Shawkshank Redemption (Stephen King 1994)</li>
                    <li>- Once Upon a Time in Hollywood (Tarantino 2019)</li>
                    <li><span>- <s>Memories of Murder (Bong 2005)</s></span></li>
                    <li><span>- <s>Lady Bird (Gerwig 2017)</s></span></li>
                    <li>- Fallen Angels (Wong Kar-wai 1995)</li>
                    <li><span>- <s>The Wandering Earth 2 (Gwo 2023)</s></span></li>
                    <li>- Perfect Days (Wenders 2023)</li>
                    <li>- Anatomy of a Fall (Triet 2023)</li>
                    <li>- Poor Things (Lanthimos 2023)</li>
                    <li>- Aftersun (Wells 2022)</li>
                    <li><span>- <s>20th Century Girl (Bang 2022)</s></span></li>
                    <li>- Wheel of Fortune and Fantasy (Hamaguchi 2021)</li>
                    <li>- Fallen Leaves (Kaurismäki 2023)</li>

                </ul>
                </p>
            
            
            </div>
               
                </Dropdown>
            
           
    
            

                <Dropdown buttonContent={<p className="movie-folder">favorites</p>}>
                <div className="movie-tabs">
                    <h4>the list</h4>
                </div>
            <div className="blog-page-my-words">
                <p>an unusual 'andy' set of films :]</p>
            </div>
                <div className="blog-page-image-grid">
                    <a href="https://www.youtube.com/watch?v=13MFPoNIRaw" target="_blank" rel="noopener noreferrer">
                        <img src={HTTYD2}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=WPhFqxnvUwY" target="_blank" rel="noopener noreferrer">
                        <img src={Bernadette}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href={TenThingsIHate} target="_blank" rel="noopener noreferrer">
                        <img src={TenThings}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=f3LmX2eKJ4Q" target="_blank" rel="noopener noreferrer">
                        <img src={DarkKnight}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://youtu.be/OgFK5PJSLUo?si=EFtEhIcq3SchtNm8&t=40" target="_blank" rel="noopener noreferrer">
                        <img src={threehundred}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=rVFMFuuoRuA" target="_blank" rel="noopener noreferrer">
                        <img src={FiveFeetApart}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=GgiK-HWKPjw" target="_blank" rel="noopener noreferrer">
                        <img src={Ratatouille}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=VK7DFpGJpP4&t=275s" target="_blank" rel="noopener noreferrer">
                        <img src={Rango}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=pR2i24iJTX4" target="_blank" rel="noopener noreferrer">
                        <img src={NineDays}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                        <a href="https://www.youtube.com/watch?v=VxcyuJuDmKI" target="_blank" rel="noopener noreferrer">
                        <img src={PastLives}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=dyVXM2gwKFA" target="_blank" rel="noopener noreferrer">
                        <img src={Wonderland}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.criterion.com/current/top-10-lists/567-celine-songs-top-10" target="_blank" rel="noopener noreferrer">
                        <img src={HighAndLow}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=69a5dUSSxcg" target="_blank" rel="noopener noreferrer">
                        <img src={RushHour}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=Nl8PkOiFfBw" target="_blank" rel="noopener noreferrer">
                    <img src={Gamblers}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                </div>
                </Dropdown>

                <Dropdown buttonContent={<p className="movie-folder">oldies</p>}>
                <div className="movie-tabs">
                    <h4>family treasures</h4>
                </div>
            <div className="blog-page-my-words">
                <p></p>
            </div>
                <div className="blog-page-image-grid">
                    <a href="https://www.youtube.com/watch?v=13MFPoNIRaw" target="_blank" rel="noopener noreferrer">
                        <img src={SurfNinjas}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        <img src={ThreeNinjas}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                     <a href="" target="_blank" rel="noopener noreferrer">
                        <img src={TenThings}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        <img src={PitchPerfect}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        <img src={LittleRascals}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=j2u8_ug1WmQ" target="_blank" rel="noopener noreferrer">
                        <img src={ODD}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=Nl8PkOiFfBw" target="_blank" rel="noopener noreferrer">
                        <img src={Gamblers}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                </div>
                </Dropdown>
                

                <Dropdown buttonContent={<p className="movie-folder">reyna</p>}>
                <div className="movie-tabs">
                    <h4>reyna's list</h4>
                </div>
            <div className="blog-page-my-words">
                <p></p>
            </div>
                <div className="blog-page-image-grid">
                    <a href="https://www.youtube.com/watch?v=W71mtorCZDw" target="_blank" rel="noopener noreferrer">
                        <img src={PrincessKaguya}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=0n_HQwQU8ls" target="_blank" rel="noopener noreferrer">
                        <img src={MoM}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                     <a href="https://www.youtube.com/watch?v=5YEVQDr2f3Q" target="_blank" rel="noopener noreferrer">
                        <img src={MM}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=LM2F56uK0fs" target="_blank" rel="noopener noreferrer">
                        <img src={MM2}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=KFS4_qevE7M" target="_blank" rel="noopener noreferrer">
                        <img src={CenturyGirl}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=-xSs_xsf5sI&t=26s" target="_blank" rel="noopener noreferrer">
                        <img src={UsAndThem}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=KQ0gFidlro8" target="_blank" rel="noopener noreferrer">
                        <img src={Minari}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                     <a href="https://www.youtube.com/watch?v=AjCebKn4iic" target="_blank" rel="noopener noreferrer">
                        <img src={Okja}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                  
                </div>
                </Dropdown>
                
                <Dropdown buttonContent={<p className="movie-folder">thriller</p>}>
                <div className="movie-tabs">
                    <h4>horror / mystery</h4>
                </div>
            <div className="blog-page-my-words">
                {/* <p>the conjuring cinematic universe with my friends</p> */}
                <p>hanhnhan / claire / uf pool party</p>
                        <ul><li>conjuring cinematic universe</li></ul>
            </div>
                <div className="blog-page-image-grid">
                    <a href="https://www.youtube.com/watch?v=k10ETZ41q5o" target="_blank" rel="noopener noreferrer">
                        <img src={TheConjuring}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=VFsmuRPClr4" target="_blank" rel="noopener noreferrer">
                        <img src={TheConjuring2}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=pzD9zGcUNrw" target="_blank" rel="noopener noreferrer">
                        <img src={TheNun}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    
                        <a href="https://www.youtube.com/watch?v=KisPhy7T__Q" target="_blank" rel="noopener noreferrer">
                        <img src={AnabelleCreation}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                        <a href="https://www.youtube.com/watch?v=paFgQNPGlsg" target="_blank" rel="noopener noreferrer">
                        <img src={Annabelle}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                        <a href="https://www.youtube.com/watch?v=CAeeIdD4KQU" target="_blank" rel="noopener noreferrer">
                        <img src={TheNun2}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                        <a href="https://www.youtube.com/watch?v=uOV-xMYQ7sk" target="_blank" rel="noopener noreferrer">
                        <img src={LaLlorona}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                        
                </div>
                <div className="blog-page-image-grid">
                <a href="https://www.youtube.com/watch?v=V6wWKNij_1M" target="_blank" rel="noopener noreferrer">
                <img src={Hereditary}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                <a href="https://www.youtube.com/watch?v=y0AnGfzgh_w" target="_blank" rel="noopener noreferrer">
                <img src={WorldFair}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                <a href="https://www.youtube.com/watch?v=0n_HQwQU8ls" target="_blank" rel="noopener noreferrer">
                <img src={MoM}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                </div>

                </Dropdown>

              
         
                

               
            
            {/* <div className="blog-page-title">
                    <h4>the list</h4>
                </div>
            <div className="blog-page-my-words">
                <p>an unusual 'andy' set of films :]</p>
            </div>

                <div className="blog-page-image-grid">
                    <a href="https://www.youtube.com/watch?v=13MFPoNIRaw" target="_blank" rel="noopener noreferrer">
                        <img src={HTTYD2}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=WPhFqxnvUwY" target="_blank" rel="noopener noreferrer">
                        <img src={Bernadette}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href={TenThingsIHate} target="_blank" rel="noopener noreferrer">
                        <img src={TenThings}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=f3LmX2eKJ4Q" target="_blank" rel="noopener noreferrer">
                        <img src={DarkKnight}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://youtu.be/OgFK5PJSLUo?si=EFtEhIcq3SchtNm8&t=40" target="_blank" rel="noopener noreferrer">
                        <img src={threehundred}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=rVFMFuuoRuA" target="_blank" rel="noopener noreferrer">
                        <img src={FiveFeetApart}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=GgiK-HWKPjw" target="_blank" rel="noopener noreferrer">
                        <img src={Ratatouille}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=VK7DFpGJpP4&t=275s" target="_blank" rel="noopener noreferrer">
                        <img src={Rango}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                    <a href="https://www.youtube.com/watch?v=F4ki4IHJpBY" target="_blank" rel="noopener noreferrer">
                        <img src={NineDays}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.youtube.com/watch?v=dyVXM2gwKFA" target="_blank" rel="noopener noreferrer">
                        <img src={Wonderland}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    <a href="https://www.criterion.com/current/top-10-lists/567-celine-songs-top-10" target="_blank" rel="noopener noreferrer">
                        <img src={HighAndLow}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                        <a href="https://www.youtube.com/watch?v=69a5dUSSxcg" target="_blank" rel="noopener noreferrer">
                        <img src={RushHour}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/> </a>
                </div> */}
                {/* <div className="blog-page-title">
                    <h4><u>Other</u></h4>
                </div>
                <div className="blog-page-my-words">
                <p>videos</p>
            </div>
                <Videos/> */}
                <br>
                </br>
                <wbr></wbr>
            <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>
                <Footer/>
                </div>
                

{/*                
                <div className="blog-page-title">
                    <h4></h4>
                </div>
                <div className="blog-page-my-words">
                    <p>
                        EdgeDetect[]
                    </p>
                </div>
                <div className="blog-page-images">
                    <img src={wamblyImage} 
                    style={{aspectRatio: 642/388}} 
                    width= '550px' 
                    className="blog-page-images-theImage"
                
                    />
                    <p className="figures">
                        Figure 1: EdgeDetect[]</p>
                </div> */}
                

        </>
    )
}
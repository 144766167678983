import Header from './Header';
import Footer from './homeBlogFooter';
import Home from './Home';
import Archive from './Archive';
import Blog from "./Blog";
import Paradise from "../blogs/Paradise";
import Wambly from "../blogs/Wambly";

import Travels from "../blogs/Travels";
import Notes from "../blogs/Notes";
import Watches from "../blogs/Watches";
import Reads from "../blogs/Reads";
import Animals from "../blogs/Animals";
import Twenties from "../blogs/Twenties";
import Reviews from "../blogs/Reviews";
import ToWrite from "../blogs/ToWrite";

// Current Blogs
import FormalMethods from "../blogs/FormalMethods"
import DreamMachine from "../blogs/DreamMachine"
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Outlet} from 'react-router-dom';

function CatchAll() {
  useEffect(() => {
    window.location.href = "/"
  }, []);

  return null;
}

function isCurrentRouteValid(route) {
  const validRoutes = [
    "/",
    "/Archive",
    // Exclude "/Blog" from redirection
    "/Blog/Paradise",
    "/Blog/Travels",
    "/Blog/Notes",
    "/Blog/Watches",
    "/Blog/Word",
    "/Blog/miFamilia",
    "/Blog/Twenties",
    "/Blog/Watches/Reviews",
    "/Blog/ToWrite"
  ]
  return validRoutes.includes(route);
}

export default function Router() {
  useEffect(() => {
    const handleRouteChange = () => {
      const currentRoute = window.location.pathname;
      if (!isCurrentRouteValid(currentRoute)) {
        window.location.href = '/';
      }
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  const Layout = () => {
    
    return (
      <>
        <Header />
        <Outlet />
    
      </>
    );
  };

  const BrowserRoutes = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/Archive" element={<Archive />} />
            <Route path="/Blog" element={<Blog />} />

            

            {/* Blogs */}
            {/* <Route path="/Blog/Hallucination" element={<Formal/>} /> */}
            <Route path="/Blog/Do-as-I-Say-Not-as-I-Do" element={<FormalMethods/>} />
            <Route path="/Blog/DreamMachine" element={<DreamMachine/>} />
            <Route path="/Blog/Twenties" element={<Twenties />} />
            <Route path ="/Blog/ToWrite" element = {<ToWrite/>}/>


            {/* Archived Blogs */}
            <Route path="/Blog/miFamilia" element={<Animals />} />
            <Route path="/Blog/Paradise" element={<Paradise />} />
            <Route path="/Blog/Wambly" element={<Wambly />} />


            {/* Notes */}
            <Route path="/Blog/Watches" element={<Watches />} />
            <Route path="/Blog/Words" element={<Reads />} />
            <Route path="/Blog/Travels" element={<Travels />} />
            <Route path="/Blog/Notes" element={<Notes />} />

  
            {/* Movies */}
            <Route path="/Blog/Watches/Reviews" element={<Reviews/>} />

        





            {/* <Route path="/Blog/Watches/Lists" element={<Lists/>} />
            <Route path="/Blog/Watches/2023" element = {<Test1/>}/> */}




            <Route path="*" element={<CatchAll />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return <BrowserRoutes />;
}

import React, { useState, useRef, useEffect } from 'react';
import "../App.css"

const DropdownNotes = ({ buttonContent, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonToggled, setIsButtonToggled] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsButtonToggled(!isButtonToggled);
  };

  useEffect(() => {
    if (isOpen) {
      dropdownRef.current.style.height = `${dropdownRef.current.scrollHeight}px`;
    } else {
      dropdownRef.current.style.height = '0';
    }
  }, [isOpen]);

  const handleItemClick = (item) => {
    console.log(`Selected item: ${item}`);
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    toggleMenu();
  };

  return (
    <div className="dropdown">
      <button
        onClick={handleButtonClick}
        className={`dropdown-toggle-notes ${isButtonToggled ? 'button-toggled-notes' : ''}`}
        ref={buttonRef}
      >
        <span className="button-content">{buttonContent}</span>
        <span className="button-icon">{isOpen ? '▼' : '▲'}</span>
      </button>
      <div
        className={`dropdown-menu ${isOpen ? 'open' : ''}`}
        ref={dropdownRef}
      >
        {React.Children.map(children, (child, index) => {
          return (
            <div
              onClick={() => handleItemClick(child.props.children)}
              key={index}
              className="dropdown-item"
            >
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DropdownNotes;
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import daynight from "../assets/day-and-night.png";
import { Sun, Moon } from 'lucide-react';


// import "react-toggle/style.css" // for ES6 modules

export default function Header () {
    
    const [mode, setMode] = useState('light'); // State variable to track mode

  // Function to toggle mode
  const toggleMode = () => {
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // Apply theme based on mode
  useEffect(() => {
    if (mode === 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [mode]);
    
    return(

      <div className='nav-bar'>
          <Link className="pages" to="/">01  :  Home</Link>
          <p className="pages-line">|</p>
          <Link className="pages" to="/Archive">02  :  Archive</Link>
          <p className="pages-line">|</p>
          <Link className="pages" to="/Blog">03  :  Blog</Link>
          <span
              onClick={toggleMode}
              className="rounded-full display-flex items-center justify-center"
              id="tog"
              aria-label={`Switch to ${mode === 'light' ? 'dark' : 'light'} mode`}
          >
              {mode === 'dark' ? (
                  <Moon size="1.2rem" color="#F0F0F5"/>
              ) : (
                  <Sun size="1.2rem" className="text-yellow-400" />
              )}
          </span>
      </div>
    )
}
import { BrowserRouter, Route, Routes, Outlet, Link } from 'react-router-dom';
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style

import Footer from "../archive/Footer"

// proposal 3
import BlogList from "./BlogList"

import DEMO from "../assets/FormalMethods/DEMO.jpg"

// dash &#x23E4;



export default function DreamMachine() {
    return (
        <>
            <div className="blog-page">
                <BlogList />

                <div className="upvote-and-title">
                    {/* <ItemTest itemId={3}/> */}
                    <h2>DAISY: Do as I Say Not as I Do</h2>
                    <date><i>- x minute read</i></date>
                </div>     

                
                <div className="blog-page-quote">
                    <ol>
                        <li>tell me what you know.</li>
                        <li>tell me what you don't know.</li>
                        <li>then tell me what you think.</li>
                        <li>always distinguish which from which.</li>
                    </ol>
                    <p><i>- Colin Powell, Former Secretary of State</i></p>
               </div>

             

                <div className="blog-page-my-words">
                    <h3>Formal Methods in AI and Robotics</h3>
                </div>
                <div className="blog-page-my-words">
                    <p>I am taking this class to learn about how computers reason about the world and to understand what problems 21st century machines are facing. Prior knowledge to Formal Methods is minimal with some mild interest in Automata Theory and Theory of Computation. I want to learn more about why autonomy is important and how it affects the things we use in our lives.</p>
                    <p>This page tracks the progress of some of my works in this class, particularly my final project.</p>
                </div>
                <div className="blog-page-my-words">
                    <h4 style={{borderBottom:"2px solid #2b2b2c"}}>Game Theory</h4>
                </div>

                <div className="blog-page-my-words">
                    <p><a href="http://www.notcharted.xyz/" target="_blank" rel="noopener noreferrer">A game I made.</a></p>
                    <p>This experiment was around 15 kilobytes. It could easily fit on a floppy disk. I was interested in zero-sum games, particularly because of <a href="https://en.wikipedia.org/wiki/Nash_equilibrium" target="_blank" rel="noreferrer">nash equillibrium.</a></p>
                    <p>I'd love to discuss more about what's under the hood, but I need more time. What the machine does do is find the best strategy to never lose using the minimax algorithm.</p>
                    <p>In the future, I might explore other games like Pacman or Block the Pig.</p>
                </div>

                <div className="blog-page-my-words">
                    <h4 style={{borderBottom:"2px solid #2b2b2c"}}>Final Project</h4>
                    <b>Do as I Say Not as I Do: Natural Language, LTL, and Ontology</b>
                </div>

                <div className="blog-page-my-words">
                    <p>We present DAISY, an exciting approach to hybridize Natural Language and LTL specification. The fickle nature of LLMs does not syngerize with the hardy LTL. We introduce Ontology to mediate such translations.</p>
                    <p>I originally wanted to work on a Perception Topic with my graduate labmates, but had forgotten I signed up with other undergraduates in the class. 
                        I'm still working with my labmates outside of class, which is nice. Might have accidentally doubled my workload, but I love an upwind trajectory.
                    </p>
                    <p>
                        Translating natural langauage to LTL is rightfully intimidating because LLMs hallucinate, which is not typically compatible with the rigorous LTL. Depending on the context, there are 
                        a few promising answers to this. The natural answer is to use Retrieval Augmented Generation (RAG).
                        This is currently the best way to use LLMs to answer questions regarding a domain-specific content space.
                        The problem is, this type of retrieval is best for queries, not necessarily commands. A command is an instruction,
                        and you want your machine to perform in a particular way.
                    </p>
                    <p> 
                    <h4>Do as I Say:</h4>
                    This is where Ontology 
                        comes in. What Ontology can do is provide a structured vocabulary of domain-specific concepts. This is the formal bridge between natural language terms and temporal terms.
                    </p>
                    <div>
                        <p>NL: "Always ensure A before B"</p>
                        <p>Ontology:</p>
                        <ul>
                            <li>"Always" → Globlal operator (G)</li>
                            <li>"before" → Temporal precedence</li>
                        </ul>
                        <p>Results in LTL: G(A → X(¬B U A))</p>
                    </div>
                    <p>This is a variable example, but imagine telling an autonomous vehicle to "Go through the light." The instruction did not mention
                        anything particular about the environment. Should the vehicle accelerate? Should it yield first? Temporally, and to a machine, the instruction is not well defined. A human might
                        understand this instruction, and stop the car if there is a red light.
                    </p>
                    <p>
                        Here is an example using NL2LTL
                    </p>
                    <div className='blog-page-my-words'>
                    <div className="blog-carousel-images">
                        <img src={DEMO} style={{ width: "500px" }}></img>
                        <p className="figures">Figure 1: demo </p>
                    </div>
                </div>
                    <p>This uses gpt-3.5-turbo, with tailored prompting handcrafted by me. It curates a few things for us. 
                        Firstly, it is able to perform the translations. 
                        Secondly, it can tell me the variables it recognizes.
                        It is also telling us how much confidence the model has for this particular translation. More on this confidence level later.
                        The idea is that with Ontology, this confidence level is, on average, higher.</p>

                    <h4>Not as I Do:</h4>
                    <p>This is a popular friction in human-computer interaction. You are trying to tell a computer a human instruction, not a machine instruction.
                        
                    </p>
                    
                    <p>What if you gave your car a driver's handbook? Would it fly, or would it die?
                        
                    </p>
                    <p>Like RAG algorithms, ontologies enhance the LLMs ability to ingest domain-specific knowledge and captures semantic relationships between various workflows or activities. As a result, you get amazing translation accuracy, which only compounds its ability to validate translations against domain constraints.
                    </p>
                        
                        
                   
                </div>


                

      
                <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>
                <Footer />
            </div>
        </>
    )
}

import React, {useEffect, useRef, useState} from 'react';
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import wamblyImage from "../assets/edgedetect()"

import LD from "../components/LD"

import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"

import Footer from "../archive/Footer"

// movienavbar
import Reviews from "./Reviews.js"
import Lists from "./Lists.js"
import Diary2023 from "./Diary2023.js"
import Diary2024 from "./Diary2024.js"
import DiaryRecents from "./DiaryRecents.js"



// List of 2023
import Spider from "../assets/AcrossTheSpiderVerse.jpg"
import Barbie from "../assets/Barbie.jpg"
import Whale from "../assets/Whale.jpg"
import Person2Person from "../assets/Person2Person.jpg"
import AlwaysBeMyMaybe from "../assets/AlwaysBeMyMaybe.jpg"
import SmallSlowButSteady from "../assets/SmallSlowButSteady.jpg"
import Mid90s from "../assets/Mid90s.jpg"
import Beef from "../assets/Beef.jpg"
import BrideOfHalloween from "../assets/BrideOfHalloween.jpg"
import LittleWomen from "../assets/LittleWomen.jpg"
import TwentiethCenturyWomen from "../assets/20thCenturyWomen.jpg"
import DawnOfThePlanetOfTheApes from "../assets/DawnOfThePlanetOfTheApes.jpg"
import DarkKnight from "../assets/DarkKnight.jpg"
import TheMonkeyKing from "../assets/TheMonkeyKing.jpg"
import GretaLittleWomen from "../assets/GretaLittleWomen.jpg"
import BulletTrain from "../assets/BulletTrain.jpg"
import OceansEight from"../assets/OceansEight.jpg"
import Wolf from"../assets/WolfOfWallStreet.jpg"
import LadyBird from "../assets/LadyBird.jpg"
import Hereditary from "../assets/Hereditary.jpg"
import PrincessKaguya from "../assets/PrincessKaguya.jpg"
import Cloudy2 from "../assets/Cloudy2.jpg"
import MoM from "../assets/MemoriesOfMurder.jpg"
import MM from "../assets/MurderMystery.jpg"
import MM2 from "../assets/MurderMystery2.jpg"
import CenturyGirl from "../assets/20thCenturyGirl.jpg"
import UsAndThem from "../assets/UsAndThem.jpg"
import Minari from "../assets/Minari.jpg"
import WillyWonka from "../assets/WillyWonka.jpg"
import Okja from "../assets/Okja.jpg"
import ODD from "../assets/OperationDumboDrop.jpg"


// The List :)
import TenThingsIHate from "../assets/Poems/10Things.pdf"
import FiveFeetApart from "../assets/FiveFeetApart.jpg"
import HTTYD from "../assets/HTTYD.jpg"
import HTTYD2 from "../assets/HTTYD2.jpg"
import Bernadette from "../assets/Bernadette.jpg"
import TenThings from "../assets/10Things.jpg"
import threehundred from "../assets/300.jpg"
import Ratatouille from "../assets/Ratatouille.jpeg"
import Rango from "../assets/Rango.jpg"
import NineDays from "../assets/NineDays.jpg"
import Wonderland from "../assets/AliceInWonderland.jpg"
import HighAndLow from "../assets/High&Low.jpg"
import RushHour from "../assets/RushHour.jpg"

// horror
import TheConjuring from "../assets/TheConjuring.jpg"
import TheConjuring2 from "../assets/TheConjuring2.jpg"
import TheNun from "../assets/TheNun.jpg"
import AnabelleCreation from "../assets/AnabelleCreation.jpg"
import Annabelle from "../assets/Annabelle.jpg"
import TheNun2 from "../assets/TheNun2.jpg"

import WorldFair from "../assets/WorldFair.jpg"



// Testing Videos
import Videos from "../archive/Videos"

// List of Blogs
import BlogList from "../blogs/BlogList"


// Logos
import NG from "../assets/NG/northrop-grumman-logo-2.png"



// Dropdown 
import Dropdown from "./Dropdown";
import DropdownNotes from "./DropdownNotes";


// dash &#x23E4;






export default function Watches() {

   const [selectedComponent, setSelectedComponent] = useState('Recents');

   const handleButtonClick = componentName => {
    setSelectedComponent(componentName);

   };

   const renderComponent = () => {
    switch (selectedComponent) {

        case 'Home':
            return <Watches/>;
        case 'Recents':
            return <DiaryRecents/>;

        case 'Reviews':
            return <Reviews />;
        case 'Lists':
            return <Lists />;
        case "Diary2023":
            return <Diary2023 />;

        case "Diary2024":
            return <Diary2024/>;

        
            default:
                return null;
        
    }
   };

    useEffect(() => {
        const handleButtonClick = event => {
          const clickedButton = event.target;
    
          if (!clickedButton.closest('.seasons')) {
            return; // Clicked outside the .seasons div
          }
    
          const buttons = document.querySelectorAll('.seasons')
    
          buttons.forEach(button => {
            if (button !== clickedButton) {
              button.classList.remove('active');
            }
          });
    
          clickedButton.classList.add('active');
        };
    
        document.addEventListener('click', handleButtonClick);
    
        return () => {
          document.removeEventListener('click', handleButtonClick);
        };
      }, []);
    
    return (
        <>
        <div className="blog-page">
            <BlogList/>

        
        
                <div className="blog-page-title">
                    <h3>Movies</h3>
                </div>
            
            <div className = "seasons-container">
                <div className = "seasons">
               
                
                <button onClick={() => handleButtonClick('Reviews')}>
                    Reviews
                </button>
                <button onClick={() => handleButtonClick('Lists')}>
                    Lists
                </button>
                <button onClick={() => handleButtonClick('Diary2023')}>
                    2023
                </button>
                <button onClick={() => handleButtonClick('Diary2024')}>
                    2024
                </button>
                   
                    {/* <button>2024</button> */}
                </div>

                </div>

        

          

                {/* <div className="blog-page-date">
                    <p></p>
                </div> */}
                {/* <div className="blog-page-quote">
                    <p><i>"Let's get lost" - wammis</i></p>
                </div> */}
               
               <div className="component-container">
                {renderComponent()}
               </div>
                


                {/* ------------------------------------------------------ */}
               
           
                </div>
                

{/*                
                <div className="blog-page-title">
                    <h4></h4>
                </div>
                <div className="blog-page-my-words">
                    <p>
                        EdgeDetect[]
                    </p>
                </div>
                <div className="blog-page-images">
                    <img src={wamblyImage} 
                    style={{aspectRatio: 642/388}} 
                    width= '550px' 
                    className="blog-page-images-theImage"
                
                    />
                    <p className="figures">
                        Figure 1: EdgeDetect[]</p>
                </div> */}
                

        {/* <Footer/> */}
        </>
    )
}
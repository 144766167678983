
import React, {useEffect, useRef, useState} from 'react';
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import wamblyImage from "../assets/edgedetect()"

import LD from "../components/LD"

import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"

import Footer from "../archive/Footer"

// List of 2023
import Spider from "../assets/AcrossTheSpiderVerse.jpg"
import Barbie from "../assets/Barbie.jpg"
import Whale from "../assets/Whale.jpg"
import Person2Person from "../assets/Person2Person.jpg"
import AlwaysBeMyMaybe from "../assets/AlwaysBeMyMaybe.jpg"
import SmallSlowButSteady from "../assets/SmallSlowButSteady.jpg"
import Mid90s from "../assets/Mid90s.jpg"
import Beef from "../assets/Beef.jpg"
import BrideOfHalloween from "../assets/BrideOfHalloween.jpg"
import LittleWomen from "../assets/LittleWomen.jpg"
import TwentiethCenturyWomen from "../assets/20thCenturyWomen.jpg"
import DawnOfThePlanetOfTheApes from "../assets/DawnOfThePlanetOfTheApes.jpg"
import DarkKnight from "../assets/DarkKnight.jpg"
import TheMonkeyKing from "../assets/TheMonkeyKing.jpg"
import GretaLittleWomen from "../assets/GretaLittleWomen.jpg"
import BulletTrain from "../assets/BulletTrain.jpg"
import OceansEight from"../assets/OceansEight.jpg"
import Wolf from"../assets/WolfOfWallStreet.jpg"
import LadyBird from "../assets/LadyBird.jpg"
import Hereditary from "../assets/Hereditary.jpg"
import PrincessKaguya from "../assets/PrincessKaguya.jpg"
import Cloudy2 from "../assets/Cloudy2.jpg"
import MoM from "../assets/MemoriesOfMurder.jpg"
import MM from "../assets/MurderMystery.jpg"
import MM2 from "../assets/MurderMystery2.jpg"
import CenturyGirl from "../assets/20thCenturyGirl.jpg"
import UsAndThem from "../assets/UsAndThem.jpg"
import Minari from "../assets/Minari.jpg"
import WillyWonka from "../assets/WillyWonka.jpg"
import Okja from "../assets/Okja.jpg"
import ODD from "../assets/OperationDumboDrop.jpg"
import NightSwim from "../assets/2024/NightSwim.jpg"
import Interstellar from "../assets/Interstellar.jpg";
import WeLiveInTime from "../assets/2024/WeLiveInTime.jpg";



// The List :)
import TenThingsIHate from "../assets/Poems/10Things.pdf"
import FiveFeetApart from "../assets/FiveFeetApart.jpg"
import HTTYD from "../assets/HTTYD.jpg"
import HTTYD2 from "../assets/HTTYD2.jpg"
import Bernadette from "../assets/Bernadette.jpg"
import TenThings from "../assets/10Things.jpg"
import threehundred from "../assets/300.jpg"
import Ratatouille from "../assets/Ratatouille.jpeg"
import Rango from "../assets/Rango.jpg"
import NineDays from "../assets/NineDays.jpg"
import Wonderland from "../assets/AliceInWonderland.jpg"
import HighAndLow from "../assets/High&Low.jpg"
import RushHour from "../assets/RushHour.jpg"

// horror
import TheConjuring from "../assets/TheConjuring.jpg"
import TheConjuring2 from "../assets/TheConjuring2.jpg"
import TheNun from "../assets/TheNun.jpg"
import AnabelleCreation from "../assets/AnabelleCreation.jpg"
import Annabelle from "../assets/Annabelle.jpg"
import TheNun2 from "../assets/TheNun2.jpg"

import WorldFair from "../assets/WorldFair.jpg"



// Testing Videos
import Videos from "../archive/Videos"

// List of Blogs
import BlogList from "../blogs/BlogList"


// Logos
import NG from "../assets/NG/northrop-grumman-logo-2.png"



// Dropdown 
import Dropdown from "./Dropdown";
import DropdownNotes from "./DropdownNotes";


// dash &#x23E4;






export default function Reviews() {

    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleButtonClick = componentName => {
     setSelectedComponent(componentName);
 
    };
 
    const renderComponent = () => {
     switch (selectedComponent) {
         case 'Reviews':
             return <Reviews />;
       
         
             default:
                 return null;
         
     }
    };
    useEffect(() => {
        const handleButtonClick = event => {
          const clickedButton = event.target;
    
          if (!clickedButton.closest('.seasons')) {
            return; // Clicked outside the .seasons div
          }
    
          const buttons = document.querySelectorAll('.seasons button');
    
          buttons.forEach(button => {
            if (button !== clickedButton) {
              button.classList.remove('active');
            }
          });
    
          clickedButton.classList.add('active');
        };
    
        document.addEventListener('click', handleButtonClick);
    
        return () => {
          document.removeEventListener('click', handleButtonClick);
        };
      }, []);
    
    return (
        <>
        <div className="blog-page">
            
                {/* <div className="blog-page-title">
                    <h3>Movies</h3>
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>
                */}


                {/* ------------------------------------------------------ */}
                <div className="blog-page-title">
                    <h4>Recent Reviews</h4>
            
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>
                <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                        <img src={WeLiveInTime}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/>
                </div>
                <div className= "right-side">
                    <p className=""><b>We Live In Time (2024)</b></p>

                    <div className="star-date">
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <span>watched nov 10</span>
                    </div>
                    <div className="right-side-movie-quotes">
                        <p>"There's a very distinct and real possibility that I am about to fall in love with you"</p>
                    </div>
                    <div className="right-side-movie-notes">
                        <p>florence pugh!</p>
                    </div>
                   
                </div>
                </div> 
                <div className="bottom-side-movie-comments">
                    <p>i guess i'm spending the rest of my nights sitting in the middle of the highway.</p>
                </div>
 
            </div>
                {/*  */}
                <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                        <img src={Interstellar}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/>
                </div>
                <div className= "right-side">
                    <p className=""><b>Interstellar (2014)</b></p>
                    {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                    <div className="star-date">
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <span>watched jan 15</span>
                    </div>
                    <div className="right-side-movie-notes">
                        <p>matt damon!</p>
                    </div>
                   
                </div>
                </div> 
                <DropdownNotes buttonContent={<p className="movie-folder">notes</p>}>
                <div className="bottom-side-movie-comments">
                        <p>Do not go gentle into that good night,</p>
                        <p>Old age should burn and rave at close of day;</p>
                        <p>Rage, rage against the dying of the light.</p>
                        <br></br>
                        <p>Though wise men at their end know dark is right,</p>
                        <p>Because their words had forked no lightning they</p>
                        <p>Do not go gentle into that good night.</p>
                        <br></br>
                    </div> 
                </DropdownNotes>
            </div>
                
        
                <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                        <img src={WillyWonka}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>
                </div>
                <div className= "right-side">
                    <p className=""><b>Willy Wonka & the Chocolate Factory (1971)</b></p>
                    {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                    <div className="star-date">
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p>
                    <span>watched dec 1</span>
                    </div>
                    <div className="right-side-movie-quotes">
                        <p>we are the music makers</p>
                        <p>and we are the dreamers of dreams</p>
                        
                    </div>
                   
                </div>
                </div> 
                <DropdownNotes buttonContent={<p className="movie-folder">notes</p>}>
                <div className="bottom-side-movie-comments">
                        <p>'Round the world and home again</p>
                        <p>That's the sailor's way!</p>
                        <p>Faster faster, faster faster</p>
                        <br></br>
                        <p>There's no earthly way of knowing</p>
                        <p>Which direction we are going</p>
                        <p>There's no knowing where we're rowing</p>
                        <p>Or which way the river's flowing</p>
                        <br></br>
                        <p>Is it raining, is it snowing?</p>
                        <p>Is a hurricane a-blowing?</p>
                        <br></br>
                        <p>Not a speck of light is showing</p>
                        <p>So the danger must be growing</p>
                        <p>Are the fires of hell a-glowing?</p>
                        <p>Is the grisly reaper mowing?</p>
                        <br></br>
                        <p>Yes, the danger must be growing</p>
                        <p>For the rowers keep on rowing</p>
                        <p>And they're certainly not showing</p>
                        <p>Any signs that they are slowing.</p>
                        <br></br>
                    </div> 
                </DropdownNotes>
            </div>
            <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                <a href="https://www.youtube.com/watch?v=KFS4_qevE7M" target="_blank" rel="noopener noreferrer">
                        <img src={CenturyGirl}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>
                </div>
                <div className= "right-side">
                    <p className=""><b>20th Century Girl (2022)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                   
                    <div className="right-side-movie-quotes">
                       <p>"maybe friendship and love aren't so different after all"
                        </p>
                    
                    </div>
                </div>
                </div>   
            </div>
            <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                <a href="https://www.youtube.com/watch?v=cNi_HC839Wo" target="_blank" rel="noopener noreferrer">
                        <img src={LadyBird}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>
                </div>
                <div className= "right-side">
                    <p className=""><b>Lady Bird (2017)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"different things can be sad. it's not all war."</i></p>
                        <p><i>"nothing really&#x23E4; nothing"</i></p>
                    </div>
                </div>
                </div>
                <div className="bottom-side-movie-comments">
                       <div className="conversation">
                        <div className="conversation-line">
                            <p className="conversation-character"><b>L:</b></p>
                            <p className="conversation-text">I just wish&#x23E4; I wished that you liked me</p>
                        </div>
                       
                        <div className="conversation-line">
                            <p className="conversation-character"><b>M:</b></p>
                            <p className="conversation-text">Of course I love you</p>
                        </div>  
                        </div>
                    </div>          
            </div>
            <div className = "archive-list-container❤️">
             <div className ="movie-title-container">
                <div className = "left-side">
                    <a href="https://www.youtube.com/watch?v=zmHpJ1YuzvY" target="_blank" rel="noopener noreferrer">
                    <img src={Person2Person}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>
                </div>
                <div className= "right-side">
                    <p className=""><b>Person to Person (2017)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"I know, and I love you for that"</i></p>
                    </div>
                    
                </div>
                </div>
                {/* <div className="bottom-side-movie-comments">
                        <p>whoever wrote this should ride a bike in new york.</p>
                        <p>what a waste of Tavi Gevinson and Michael Cera.</p>
                    </div> */}
                {/* ------ */}
        
            </div>
{/* 
            <div className = "seasons">
                <button onClick={() => handleButtonClick('Reviews')}>
                    1
                </button>
                <button onClick={() => handleButtonClick('Lists')}>
                    2
                </button>
                </div> */}




         
          
            
          
            <div className="archive-list-container❤️">
                    <div className ="movie-title-container">

                <div className = "left-side">

    
                    <a href="https://www.youtube.com/watch?v=HPICiASE0cY" target="_blank" rel="noopener noreferrer">
                    <img src={Whale}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>
                    
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>The Whale (2022)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"you."</i></p>
                        <p><i>"why?"</i></p>
                        <p><i>"because that's all I want to know about."</i></p>

                    </div>
                </div>
                </div>
                <div className="bottom-side-movie-comments">
                        <p>I should read moby dick.</p>
                        <p>hey uncle walt!</p>
                    </div>
                <DropdownNotes buttonContent={<p className="movie-folder">notes</p>}>

                <div className="bottom-side-movie-comments">
                
               
               <p><i>This apartment smells</i></p>
               <p><i>This notebook is retarded</i></p>
               <p><i>I hate everyone.</i></p>
               <p><i> - sadie sink</i></p>
               <p></p>


               
        
                    </div>
                    </DropdownNotes>
                </div>



        
            <div className="archive-list-container❤️">
                    <div className ="movie-title-container">

                <div className = "left-side">
                    <a href="https://www.youtube.com/watch?v=RgFMYz2BjR0" target="_blank" rel="noopener noreferrer">
                    <img src={Beef}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>
                    
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>Beef (2023)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"i see your life"</i></p>
                        <p><i>"you poor thing"</i></p>
                        <p><i>"i see it all"</i></p>
                    </div>
                </div>
                
            </div>
            <div className="bottom-side-movie-comments">
                <p>something of the caliber of ali wong</p>
            </div>
            <DropdownNotes buttonContent={<p className="movie-folder">notes</p>}>
            <div className="bottom-side-movie-comments">
                <p>
                    "<b>The drama of original choice</b> is 
                    that it goes on moment by moment for an entire lifetime, that it occurs without reason, before 
                    any reason, that freedom is there as if it were present only in the form of contingency. 
                    This contingency recalls, in a way, the arbitrariness of the grace distributed by God in Calvinistic doctrine.
                    Here too there is a sort of predestination issuing not from an external tyranny but from the operation of the subject itself. 
                    Only, we think that man has always a possible recourse to himself.
                    There is no choice so unfortunate that he cannot be saved."</p>
                <br></br>
                <p><i>- simone de beauvoir</i></p>
               
            </div>
            </DropdownNotes>
            </div>

            <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                
                    <a href="https://www.youtube.com/watch?v=mBx1py_TbwU" target="_blank" rel="noopener noreferrer">
                    <img src={Mid90s}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>
                </div>
                <div className= "right-side">
                    <p className=""><b>mid90s (2018)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"just say nothing"</i></p>
                        <p><i>"thank you"</i></p>

                    </div>
                    
                </div>
                </div>
                <div className="bottom-side-movie-comments">
                        <p>every kid needs a ray in their life.</p>
                    </div>
            </div>
    

      

            <div className="archive-list-container❤️">
                <div className ="movie-title-container">

                    <div className = "left-side">
                    
                        
                        <a href="https://www.youtube.com/watch?v=KQkX7rvDqJM" target="_blank" rel="noopener noreferrer">
                        <img src={DawnOfThePlanetOfTheApes}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>  
                    </div>
                    <div className= "right-side">
                        <p className=""><b>Dawn of the Planet of the Apes (2014)</b></p>
                        <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                        <div className="right-side-movie-notes">
                        <p><i>"if you're human, you better say so!"</i></p>
                        
                        </div>
                    </div>
                 </div>
                <div className="bottom-side-movie-comments">
                    <p>if my tdunkers had to pick <b><i>one</i></b> movie&mdash;</p>
                    <p>but what about godzilla vs. kong?</p>
                </div>
            </div>

          
            <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                    <a href="https://www.youtube.com/watch?v=0-TtAYgZGwA" target="_blank" rel="noopener noreferrer">
                    <img src={GretaLittleWomen}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a> 
                </div>
                <div className= "right-side">
                    <p className=""><b>Little Women (2019)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p>
                    {/* <div className="right-side-movie-quotes">
                        <p><i>"i'm not a poet, i'm just a woman"</i></p>
                        <p><i>"i want to be great, or nothing"</i></p>
                    </div> */}
                    <div className="right-side-movie-quotes">
                        <p><i>"you look beautiful. you are beautiful"</i></p>
                    </div>
                    
                </div>
                </div>
                <div className="bottom-side-movie-comments">
                        <p>there is a flavor of this film that always reminds me of a family i used to know.</p>
                    </div>
            </div>
                            <div className="archive-list-container❤️">
            <div className ="movie-title-container">
                <div className = "left-side">
                <a href="https://youtu.be/1ZCrqsK97i0?si=_ahLD4jJuzq1Vveu&t=46" target="_blank" rel="noopener noreferrer">
                        <img src={NineDays}style={{aspectRatio: 183/275}} width= "70px"className="movie-images"/></a>
                </div>
                <div className= "right-side">
                    <p className=""><b>Nine Days (2020)</b></p>
                    {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                    <div className="star-date">
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <span></span>
                    </div>
                    <div className="right-side-movie-notes">
                        <p>uncle walt :)</p>
                    </div>
                   
                </div>
                </div> 
                <DropdownNotes buttonContent={<p className="movie-folder">notes</p>}>
                <div className="bottom-side-movie-comments" >
                        <p>The last scud of sun holds back for me</p>
                        <p>It flings my likeness after the rest</p>
                        <p>And as true as any on the shadow'd wilds</p>
                        <p>It coaxes me to the vapor and the dusk.</p>
                        <br></br>
                        <p>I depart as air</p>
                        <p>I effuse my flesh in eddies and drift it in lacy jags</p>
                        <p>And to die is different than anyone supposed and luckier</p>
                        <p>I bequeath myself to the dirt to grow from the grass I love</p>
                        <p>If you want me again look for me under your boot-soles</p>
                        <br></br>
                        <p>You will hardly know who I am or what I mean</p>
                        <p>But I shall be good health to you nevertheless</p>
                        <p>Failing to fetch me at first keep encouraged</p>
                        <p>Missing me one place search another</p>
                        <p>I stop someplace waiting for you.</p>
                    </div> 
                </DropdownNotes>
            </div>
            <wbr>
            </wbr>
            <br></br>
            <p></p>
                </div>
                <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>
                <Footer/>
        </>
            )
                }
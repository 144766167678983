import Footer from "./Footer"
import React from "react";
import LEDButton from "./LEDButton.js";
export default function Home() {
    return (
        <>
        <div className="home-n-blog-page">
        <h1>Andy Tran</h1>
        <div className="blog-page-my-words">
            <span>I am a human.</span>
            <br></br>
            <span>I grew up with 8 brothers and 6 sisters in Pensacola.</span>
         
 
      


        </div>
      
        <Footer/>
        </div>
        </>
    )
}


import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet, Link } from 'react-router-dom';
import wamblyImage from "../assets/edgedetect()"

import LD from "../components/LD"

import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"

import Footer from "../archive/Footer"

// List of 2023
import Spider from "../assets/AcrossTheSpiderVerse.jpg"
import Barbie from "../assets/Barbie.jpg"
import Whale from "../assets/Whale.jpg"
import Person2Person from "../assets/Person2Person.jpg"
import AlwaysBeMyMaybe from "../assets/AlwaysBeMyMaybe.jpg"
import SmallSlowButSteady from "../assets/SmallSlowButSteady.jpg"
import Mid90s from "../assets/Mid90s.jpg"
import Beef from "../assets/Beef.jpg"
import BrideOfHalloween from "../assets/BrideOfHalloween.jpg"
import LittleWomen from "../assets/LittleWomen.jpg"
import TwentiethCenturyWomen from "../assets/20thCenturyWomen.jpg"
import DawnOfThePlanetOfTheApes from "../assets/DawnOfThePlanetOfTheApes.jpg"
import DarkKnight from "../assets/DarkKnight.jpg"
import TheMonkeyKing from "../assets/TheMonkeyKing.jpg"
import GretaLittleWomen from "../assets/GretaLittleWomen.jpg"
import BulletTrain from "../assets/BulletTrain.jpg"
import OceansEight from "../assets/OceansEight.jpg"
import Wolf from "../assets/WolfOfWallStreet.jpg"
import LadyBird from "../assets/LadyBird.jpg"
import Hereditary from "../assets/Hereditary.jpg"
import PrincessKaguya from "../assets/PrincessKaguya.jpg"
import Cloudy2 from "../assets/Cloudy2.jpg"
import MoM from "../assets/MemoriesOfMurder.jpg"
import MM from "../assets/MurderMystery.jpg"
import MM2 from "../assets/MurderMystery2.jpg"
import CenturyGirl from "../assets/20thCenturyGirl.jpg"
import UsAndThem from "../assets/UsAndThem.jpg"
import Minari from "../assets/Minari.jpg"
import WillyWonka from "../assets/WillyWonka.jpg"
import Okja from "../assets/Okja.jpg"
import ODD from "../assets/OperationDumboDrop.jpg"


// The List :)
import TenThingsIHate from "../assets/Poems/10Things.pdf"
import FiveFeetApart from "../assets/FiveFeetApart.jpg"
import HTTYD from "../assets/HTTYD.jpg"
import HTTYD2 from "../assets/HTTYD2.jpg"
import Bernadette from "../assets/Bernadette.jpg"
import TenThings from "../assets/10Things.jpg"
import threehundred from "../assets/300.jpg"
import Ratatouille from "../assets/Ratatouille.jpeg"
import Rango from "../assets/Rango.jpg"
import NineDays from "../assets/NineDays.jpg"
import Wonderland from "../assets/AliceInWonderland.jpg"
import HighAndLow from "../assets/High&Low.jpg"
import RushHour from "../assets/RushHour.jpg"

// horror
import TheConjuring from "../assets/TheConjuring.jpg"
import TheConjuring2 from "../assets/TheConjuring2.jpg"
import TheNun from "../assets/TheNun.jpg"
import AnabelleCreation from "../assets/AnabelleCreation.jpg"
import Annabelle from "../assets/Annabelle.jpg"
import TheNun2 from "../assets/TheNun2.jpg"

import WorldFair from "../assets/WorldFair.jpg"



// Testing Videos
import Videos from "../archive/Videos"

// List of Blogs
import BlogList from "../blogs/BlogList"


// Logos
import NG from "../assets/NG/northrop-grumman-logo-2.png"



// Dropdown 
import Dropdown from "./Dropdown";
import DropdownNotes from "./DropdownNotes";


// dash &#x23E4;






export default function Diary2023() {

    const [selectedComponent, setSelectedComponent] = useState(null);

    const handleButtonClick = componentName => {
        setSelectedComponent(componentName);

    };

    useEffect(() => {
        const handleButtonClick = event => {
            const clickedButton = event.target;

            if (!clickedButton.closest('.seasons')) {
                return; // Clicked outside the .seasons div
            }

            const buttons = document.querySelectorAll('.seasons button');

            buttons.forEach(button => {
                if (button !== clickedButton) {
                    button.classList.remove('active');
                }
            });

            clickedButton.classList.add('active');
        };

        document.addEventListener('click', handleButtonClick);

        return () => {
            document.removeEventListener('click', handleButtonClick);
        };
    }, []);

    return (
        <>
            <div className="blog-page">

                {/* <div className="blog-page-title">
                    <h3>Movies</h3>
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>
                */}


                {/* ------------------------------------------------------ */}
                <div className="blog-page-title">
                    <h4>2023</h4>

                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>



                <div className="diary-label">
                    <h5>dec</h5>
                </div>
                <div className="archive-list-container❤️">

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=KQ0gFidlro8" target="_blank" rel="noopener noreferrer">
                                <img src={ODD} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Operation Dumbo Drop (1995)</b></p>
                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                                <span>watched dec 30</span>
                            </div>
                        </div>
                    </div>

                    <hr></hr>


                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=KQ0gFidlro8" target="_blank" rel="noopener noreferrer">
                                <img src={Okja} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Okja (2017)</b></p>
                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                                <span>watched dec 30</span>
                            </div>


                        </div>
                    </div>

                    <hr></hr>

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={WillyWonka} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Willy Wonka & the Chocolate Factory (1971)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p>
                                <span>watched dec 30</span>
                            </div>


                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=KQ0gFidlro8" target="_blank" rel="noopener noreferrer">
                                <img src={Minari} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Minari (2020)</b></p>
                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                                <span>watched dec 30</span>
                            </div>
                        </div>
                    </div>



                    <hr></hr>

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=KQ0gFidlro8" target="_blank" rel="noopener noreferrer">
                                <img src={UsAndThem} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Us and Them (2018)</b></p>
                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                                <span>watched dec 30</span>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=KQ0gFidlro8" target="_blank" rel="noopener noreferrer">
                                <img src={CenturyGirl} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>20th Century Girl (2022)</b></p>
                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                                <span>watched dec 30</span>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=KQ0gFidlro8" target="_blank" rel="noopener noreferrer">
                                <img src={MM2} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Murder Mystery 2 (2023)</b></p>
                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#xBD;</p>
                                <span>watched dec 30</span>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=KQ0gFidlro8" target="_blank" rel="noopener noreferrer">
                                <img src={MM} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Murder Mystery (2019)</b></p>
                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                                <span>watched dec 30</span>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=IzC18WEfszw" target="_blank" rel="noopener noreferrer">
                                <img src={Cloudy2} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Cloudy with a Chance of Meatballs 2 (2013)</b></p>
                            <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                        </div>
                    </div>
                </div>

                <div className="diary-label">
                    <h5>nov</h5>
                </div>
                <div className="archive-list-container❤️">
                    <div className="movie-title-container">
                        <div className="left-side">
                            <img src={LadyBird} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" />
                        </div>
                        <div className="right-side">
                            <p className=""><b>Lady Bird (2017)</b></p>
                            <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>

                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">

                            <a href="https://www.youtube.com/watch?v=YAzT_wOIjAU" target="_blank" rel="noopener noreferrer">
                                <img src={Wolf} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>The Wolf of Wall Street (2013)</b></p>
                            <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>

                        </div>

                    </div>
                </div>

                <div className="diary-label">
                    <h5>oct</h5>
                </div>
                <br></br>
                <wbr></wbr>
                <p></p>
                <div className="diary-label">
                    <h5>sep</h5>
                </div>
                <div className="archive-list-container❤️">
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={OceansEight} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Ocean's Eight (2018)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={BulletTrain} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Bullet Train (2020)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={GretaLittleWomen} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Little Women (2019)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="archive-list-container❤️">
                </div>
                <div className="diary-label">
                    <h5>aug</h5>
                </div>

                <div className="archive-list-container❤️">

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={TheMonkeyKing} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>The Monkey King (2023)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#xBD;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>

                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={DawnOfThePlanetOfTheApes} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Dawn of the Planet of the Apes (2014)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>

                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={TwentiethCenturyWomen} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>20th Century Women (2016)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=0-TtAYgZGwA" target="_blank" rel="noopener noreferrer">
                                <img src={LittleWomen} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Little Women (2019)</b></p>
                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p>
                                <span></span></div>
                        </div>
                    </div>
                    <hr></hr>

                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={BrideOfHalloween} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Detective Conan: The Bride of Halloween (2022)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9734;&#xBD;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={Beef} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Beef (2023)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={Mid90s} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>mid90s (2018)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <hr>
                    </hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={AlwaysBeMyMaybe} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Always Be My Maybe (2019)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={Person2Person} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Person to Person (2017)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;</p>
                                <span></span>
                            </div>
                        </div>

                    </div>
                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={Barbie} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Barbie (2023)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>

                            </div>
                        </div>
                    </div>


                </div>
                <div className="diary-label">
                    <h5>jul</h5>

                </div>

                <div className="archive-list-container❤️">
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={Whale} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>The Whale (2022)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;</p>
                                <span></span>
                            </div>
                        </div>

                    </div>


                    <hr></hr>
                    <div className="movie-title-container">
                        <div className="left-side">
                            <a href="https://www.youtube.com/watch?v=1y8aYd9uqFY" target="_blank" rel="noopener noreferrer">
                                <img src={Spider} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" /></a>
                        </div>
                        <div className="right-side">
                            <p className=""><b>Spider-Man: Across the Spider-Verse (2023)</b></p>
                            {/* <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#xBD;</p> */}

                            <div className="star-date">
                                <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#xBD;</p>
                                <span></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <br></br>
            <wbr></wbr>
            <div className="blog-page-the-end">
                <p><i>C'est Tout</i></p>
            </div>
            <Footer />
        </>
    )
}
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import blog_image1 from "../assets/IMG_6658.jpg";
import blog_image2 from "../assets/cafe.jpg";
import blog_image3 from "../assets/apap.jpg";
import blog_image4 from "../assets/oceans.jpg";
import blog_image5 from "../assets/steak.jpg";
import blog_image6 from "../assets/hay.jpg";
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"
import BlogCarousel3 from "../components/BlogCarousel3.js"
import Footer from "../archive/Footer"

// upvote button
import ItemTest from "../components/Item.js"

import BlogList from "./BlogList"
// dash &#x23E4;




import blog_image9 from "../assets/pensacola.jpg";

export default function ToWrite() {
    return (
        <>
        <div className="blog-page">
           <BlogList/>


           <div className="upvote-and-title">
           {/* <ItemTest itemId={2}/> */}
           <h2>Writing 2023</h2>
           <date><i>- 2 minute read</i></date>
           </div>
   
                <div className = "blog-page-my-words">
                    <h4>Writing</h4>
                    <p><i>I spent the summer in Boulder reading a lot of blogs & essays. I thought writing would help me understand what I've learned and also how to ask 'good questions.'</i></p>
                </div>
              <div className = "blog-page-my-words">
                <p>After a few attempts, I realized that the stuff I liked to write was a combination of advice and thank you letters.</p>
                <p>Usually a phrase sits on my mind while I'm taking the bus or when I'm biking home at 2 am. It might take a night or a semester, but once it's done,
                    I'll write it down, and that's when the poem or blog actually gets made.
                </p>
                <p>I do have some sort of vision, but I really have no idea how it's going to turn out. The title, the message, or what I really mean to say gets figured out <i>while</i> I am writing it. 
                It's pretty interesting how even that version is something I'm not happy with. I'll spend even more time 'fixing it.'</p>

                <p>
                    The original phrase usually makes up the butter of it, but the bread is something I really want to be good. I want it to be good for many reasons, but it's ultimately
                    because of why I am writing it (it's advice or a thank you) 
                </p>
                <p>which are both very important types of words that should be carefully reviewed before sharing. Like most things, the two are best kept short and to the point.</p>
                <p>They also require a great deal of empathy for them to be "useful." The best advice you would give to anyone is advice you would either give to yourself or to
                    your kids. Most thank yous are usually hollow and you're better off finding a way to pay it forward.
                </p>
                <p>Other types of words didn't seem to make it very far. Two other top contenders were writing about friends and writing about what bothers me.</p>
                <p>But I know very little about friendship&#x23E4; and I know very little about what bothers me. 
                    Maybe that's why I have trouble. 
                    Maybe writing is a way to distract myself from what's important by writing about the importance of important things.</p>
              </div>

                
                <div className="blog-page-my-words">
                    <h4>2023</h4>
                    <p>a compilation of things that made my year:</p>
                    <ul>
                        <li>1. keepie: his identity & his advice</li>
                        <li>2. pensacola + vermont</li>
                        <li>3. colorado + california</li>
                        <li>4. staying up until the sunrise with wammis.</li>
                        <li>5. miami, but the part where we went to lotte market</li>
                        <li>5.5. and the part where we fell asleep in the living room :')</li>
                        <li>6. the people that never seem to leave my mind</li>
                    </ul>
                    <p></p>
                </div>
              
                
            
                <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>

                
            <div>

            </div>
            <Footer/>
        </div>
        </>
    )
}
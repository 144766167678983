
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';



import wamblyImage from "../assets/edgedetect()"
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"
import Footer from "../archive/Footer"
import Barbie from "../assets/Barbie.jpg"
import Whale from "../assets/Whale.jpg"
import Person2Person from "../assets/Person2Person.jpg"
import AlwaysBeMyMaybe from "../assets/AlwaysBeMyMaybe.jpg"
import SmallSlowButSteady from "../assets/SmallSlowButSteady.jpg"
import Mid90s from "../assets/Mid90s.jpg"

import BlogList from "./BlogList"
import MoviesPdf from "../assets/Poems/Movies.pdf"

// Dropdown template component
import Dropdown from "./Dropdown";


// dash &#x23E4;


export default function Notes() {
    return (
        <>
        <div className="blog-page">
            <BlogList/>
                <div className="blog-page-title">
                    <h3>Notes</h3>
                </div>
               <br></br>
                <div className="blog-page-quote">
                    <p><i>"We need not to be let alone. We need to be really bothered once in a while. How long is it since you were really bothered? About something important, about something real?"</i></p>
                    <p><i>- Ray Bradbury, Fahrenheit 451</i></p>
                </div>

                <div className="blog-page-title"><h4>Quotes:</h4></div>

            <div className="blog-page-my-words">  
                <p><i>"You know what you have to do"</i></p>
                <p><i>- Kylo Ren</i></p>
            </div>



                <div className="blog-page-title"><h4>Goethe:</h4></div>

            <div className="blog-page-my-words">  
                <p><i>"Was ich weiß, kann jeder wissen. Mein Herz hab' ich allein."</i></p>
                <p><i>- (the sorrows of young werther)</i></p>
            </div>

                <Dropdown buttonContent={<p className="movie-folder">Archived</p>}>
                    <div className="blog-page-my-words">
                        <p>some old stuff for safe-keeping</p>
                    </div>
                
               <div className="blog-page-title"><h4>EEL 3135</h4></div>
            <div className="blog-page-my-words">  
                <p>"real" life - prof. harley</p>
                <p>with scare quotes.</p>
            </div>
                
                <div className="blog-page-title"><h4>12/13/2023</h4></div>
                <div className="blog-page-my-words">
                    <p><a href="https://www.youtube.com/watch?v=T5K3yQAuLYc" target="_blank" rel="noopener noreferrer">season1.mp4</a></p>
                    <p>
                        The last frame is a set of words that reyna and james once exchanged.
                        It was such an odd response to an ordinary question.
            
                        The room bloomed with laughter,
                        but what stuck out to me the most was that guileless look on their faces.
                        They were just being themselves.
                        I remember thinking "you really have no idea how someone lives in their mind."
                        Attaching that phrase to that moment made me really happy to know them.
                    </p>
                    <p>I always wonder what they are thinking. If I asked, they would just say "nothing."</p>
                </div>
                   
            
                <div className="blog-page-title"><h4>12/10/2023</h4></div>
                <div className="blog-page-my-words">
                    <p>I recently got in touch with CEO and co-founder at Terra (YC W21), Kyriakos Eleftheriou. I often read back at what I wrote.
                    </p>
                    <p>
                    "there are times when you should rather ask for forgiveness than permission. The idea usually gives off a visceral liminal energy.
                    It might ruin your day, but it might make your year.
                    It can feel small and big in the same moment. Risky yet safe. Harmless, and somehow harmful.
                    But as long as it's right, everything before it becomes a footnote."
                    </p>

                </div>
                
                <div className="blog-page-title"><h4>11/10/2023</h4></div>
                <div className="blog-page-my-words">
                    <p>I recently had lunch with Lydia. We quickly realized we wanted to build something together. I spent the night parsing through some documentation, eating chicken wings, and watched The Conjuring with friends.
                        I hope we make something cool.
                    </p>

                </div>

                <div className="blog-page-title"><h4>11/3/2023</h4></div>
                <div className="blog-page-my-words">
                    <div className="conversation">
                        <div className="conversation-line">
                            <p className="conversation-character"><b>human 1</b></p>
                            <p className="conversation-text">"where do you live?"</p>
                        </div>

                        <div className="conversation-line">
                            <p className="conversation-character"><b>human 2</b></p>
                            <p className="conversation-text">"you mean like in real life?"</p>
                        </div>
                    </div>
                </div>

            <div className="blog-page-title"><h4>EEE 4423</h4></div>
            <div className="blog-page-my-words">  
                <p>"You have a memory stick!?"</p>
            </div>

                {/* ------------------------------------------------------ */}
                <div className="blog-page-title"><h4>10/8/2023</h4></div>
            <div className="blog-page-my-words">  
                <p>Today's vibe is grey hoodie. I love you hi-chhu's.</p>
            </div>
        
            <div className="blog-page-title"><h4>09/26/2023</h4></div>
            <div className="blog-page-quote">
                <p><i>"keep the change" - andrew</i></p>
                <p className="balloon">🎈</p>
            </div>

                <div className="blog-page-title">
                    <h4>Stranger</h4>
                </div>

                <div className="blog-page-my-words">
                    <p>I said something very uncharacteristic of myself. I'm sorry, person. Let's figure it out someday.</p>
                    <p>It is oxymoronic for something loved to be boring. Thus, the secondary suggestion is not only off the table, but the exact inversion is on top and cherry-like.</p>
                
            </div>
                <div className="blog-page-title">
                    <h4>EEL 6935</h4>
                </div>
                <div className="blog-page-my-words">
                    <div className="conversation">
                        <div className="conversation-line">
                            <p className="conversation-character"><b>human 1</b></p>
                            <p className="conversation-text">why do we care about vector spaces?</p>
                        </div>

                        <div className="conversation-line">
                            <p className="conversation-character"><b>human 2</b></p>
                            <p className="conversation-text">the wave equation doesn't work in all vector spaces</p>
                        </div>

                        <div className="conversation-line">
                            <p className="conversation-character"><b>human 1</b></p>
                            <p className="conversation-text">what do you mean by that?</p>
                        </div>

                        <div className="conversation-line">
                        <p className="conversation-character"><b>human 2</b></p>
                        <p className="conversation-text">(the hilbert space? but i back down quickly) when we work with the wave equation, we care a lot about normalized vector spaces</p>
                        </div>

                        <div className="conversation-line">
                        <p className="conversation-character"><b>human 1</b></p>
                        <p className="conversation-text">that's an interesting thought.</p>
                        </div>
                     
                      
                    </div>
                </div>
                
                <div className="blog-page-title"><h4>Before I forget</h4></div>
                <div className="blog-page-quote">
                    <p><i>Anyone know who they really are, rather than who they think they are or who they would like to be?</i></p>
                    <p><i>- robo</i></p>
                </div>
                <div className="blog-page-quote">
                    <p><i>"I don't care what you think about me. I don't think about you at all."</i></p>
                    <p><i>- coco chanel</i></p>
                </div> 
                <div className="blog-page-quote">
                    <p><i>Should we forget the real numbers?</i></p>
                    <p><i>No. They are always with us.</i></p>
                </div>
                <div className="blog-page-quote">
                    <a href={MoviesPdf}>Nine Days</a>
                </div>
                
              
                {/* <div className="blog-page-my-words">
                    <video width = "320" height = "240" controls>
                        <source src ={Work} type = "video/mp4"></source>
                        Your browser does not support the video tag
                    </video>
                </div> */}

        </Dropdown>
           
            <div className="blog-page-the-end">
            <p><i>C'est Tout</i></p>
                </div>
        
                

                
                </div>
        <Footer/>
        </>
    )
}
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useState} from 'react'
import ReactDOM from 'react-dom/client'
import Things from "../archive/Things"
import OblivionPdf from "../assets/Poems/Oblivion.pdf"
import PavanePdf from "../assets/Poems/Pavane.pdf"

import WamblyPdf from "../assets/Poems/Wambly.pdf"






const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Oblivion = () => {
  window.open(OblivionPdf)
};
const Pavane = () => {
  window.open(PavanePdf)
};
const Wambly = () => {
  window.open(WamblyPdf)
  console.log('Hello, World!')
};

export default function TestCarousel(props) {
// Initial Tile 1
    const [T1title, setT1Title] = useState('Eyes Talk')
    const [T1L1, setT1L1] = useState('Wake up')
    const [T1L2, setT1L2] = useState('So the sun could also rise')
    const [T1L3, setT1L3] = useState('And I could fall asleep.')
    const [T1L4, setT1L4] = useState('')
    const [T1L5, setT1L5] = useState('')
    const [T1L6, setT1L6] = useState("")
    const [T1L7, setT1L7] = useState(<wbr></wbr>)
    const [T1L8, setT1L8] = useState('')
// ----------------------------------------------
    const [T2title, setT2Title] = useState('quiet')
    const [T2L1, setT2L1] = useState('The moon swings between hope and despair.')
    const [T2L2, setT2L2] = useState('The stars shine everywhere')
    const [T2L3, setT2L3] = useState('Sometimes nowhere.')
    const [T2L4, setT2L4] = useState('All without a sound.')
    const [T2L5, setT2L5] = useState(<wbr></wbr>)
    const [T2L6, setT2L6] = useState("I don't know how they do it.")
    const [T2L7, setT2L7] = useState("What a strange way to die.")
    const [T2L8, setT2L8] = useState()
// ------------------------------------------------------------------------
const [T3title, setT3Title] = useState('To Love')
    const [T3L1, setT3L1] = useState('Spend a moment of your day to explore someone.')
    const [T3L2, setT3L2] = useState("It's strange.")
    const [T3L3, setT3L3] = useState("It isn't easy.")
    const [T3L4, setT3L4] = useState(<wbr></wbr>)
    const [T3L5, setT3L5] = useState('Try for a minute and you hate it.')
    const [T3L6, setT3L6] = useState('For a lifetime,')
    const [T3L7, setT3L7] = useState("And it's not enough.")
    const [T3L8, setT3L8] = useState()
    // --------------------------------------------------
    // Every Poem Under Oblivion
    // const oblivionClick = () => {
    //   setT1Title('Mourning Puddles')
    //   setT1L1('Empty puddles mark a new day.')
    //   setT1L2("Protected by the fire")
    //   setT1L3("Quietly fading away.")
    //   setT1L4("No one to watch them pass")
    //   setT1L5("As people pass all day.")
    //   setT1L6("")
    //   setT1L7("")
    //   setT1L8("")
    //   // ---------------------------------------
    //   setT2Title('Sleeping Beauty')
    //   setT2L1('A briar rose is much to hold.')
    //   setT2L2('Fresh time is always too late')
    //   setT2L3("But safely told, the magic unfolds.")
    //   setT2L4("This flower is done dying today.")
    //   setT2L5('')
    //   setT2L6('')
    //   setT2L7('')
    //   setT2L8("")
    //   // -----------------------------------
    //   setT3Title('pav')
    //   setT3L1('beyond the earth')
    //   setT3L2("she's there")
    //   setT3L3("")
    //   setT3L4("")
    //   setT3L5('')
    //   setT3L6('')
    //   setT3L7('')
    //   setT3L8("")
    // }

    // // pavane button
    // const pavaneClick = () => {
    //    setT1Title('To Love')
    //     setT1L1('Spend a moment of your day to explore someone')
    //     setT1L2("It's strange.")
    //     setT1L3("It isn't easy.")
    //     setT1L4(<wbr></wbr>)
    //     setT1L5("Try for a minute and you hate it.")
    //     setT1L6("For a lifetime,")
    //     setT1L7("And it's not enough.")
    //     setT1L8("")
    //     // ---------------------------------------
    //     setT2Title('Sleeping Beauty')
    //     setT2L1('A briar rose is much to hold.')
    //     setT2L2('Fresh time is always too late')
    //     setT2L3("But safely told, the magic unfolds.")
    //     setT2L4("This flower is done dying today.")
    //     setT2L5('')
    //     setT2L6('')
    //     setT2L7('')
    //     setT2L8("")
    //     // -----------------------------------
    //     setT3Title('pav')
    //     setT3L1('beyond the earth')
    //     setT3L2("she's there")
    //     setT3L3("")
    //     setT3L4("")
    //     setT3L5('')
    //     setT3L6('')
    //     setT3L7('')
    //     setT3L8("")
    // }
    // const wamblyClick = () => {
    //   setT1Title('To Love')
    //   setT1L1('Spend a moment of your day to explore someone')
    //   setT1L2("It's strange.")
    //   setT1L3("It isn't easy.")
    //   setT1L4(<wbr></wbr>)
    //   setT1L5("Try for a minute and you hate it.")
    //   setT1L6("For a lifetime,")
    //   setT1L7("And it's not enough.")
    //   setT1L8("")
    //   // ---------------------------------------
    //   setT2Title('Sleeping Beauty')
    //   setT2L1('A briar rose is much to hold.')
    //   setT2L2('Fresh time is always too late')
    //   setT2L3("But safely told, the magic unfolds.")
    //   setT2L4("This flower is done dying today.")
    //   setT2L5('')
    //   setT2L6('')
    //   setT2L7('')
    //   setT2L8("")
    //   // -----------------------------------
    //   setT3Title('pav')
    //   setT3L1('beyond the earth')
    //   setT3L2("she's there")
    //   setT3L3("")
    //   setT3L4("")
    //   setT3L5('')
    //   setT3L6('')
    //   setT3L7('')
    //   setT3L8("")
    // }

    

    return (
        <div className="something-big">
      
            <div className="set3-buttons">
              {/* <button className="poetry-button" onClick={oblivionClick}>Oblivion</button>
              <button className="poetry-button" onClick={pavaneClick}>Pavane</button>
              <button className="poetry-button" onClick={wamblyClick}>Wambly</button> */}
              
              <button className="poetry-button" onClick={Oblivion}>Oblivion</button>
              <button className="poetry-button" onClick={Pavane}>Pavane</button>
              <button className="poetry-button" onClick={Wambly}>Wambly</button>
            </div>
          <div 
          className="buttons-n-carousel-together"
          >
        <Carousel
arrows={true}
renderArro
        swipeable={true}
            draggable={true}
            showDots={true}

        centerMode={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={false}
//   autoPlay={props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition="transform 1200ms ease-in-out"
  transitionDuration={1000}
  containerClass="carousel-container"
//   removeArrowOnDeviceType={["tablet", "mobile"]}
    deviceType={props.deviceType}

  dotListClass="react-multi-carousel-dot-list"
  itemClass="carousel-item-padding-0-px"
  className="giant-carousel"
>
 
    {/* test-carousel-component remember this className */}
    <div className = "carousel-lines">
    <p className="L7"><wbr></wbr></p>
        <p className="L8">{T1L8}</p>
        <p className="carousel-line-title">Eyes Talk</p>
        <p className="L1">Escape from a deep gaze</p>
        <p className="L2">So the sun could also rise</p>
        <p className="L3">And I could fall asleep</p>
        {/* <p className="L4">No one to watch them pass</p>
        <p className="L5">As people pass all day.</p>
        <p className="L6">{T1L6}</p> */}
        {/* 
        
        Trapped in a deep gaze
        I reckon the night sky unsteadies
        So the sun could also rise
        And I could fall asleep

        Unfair eyes that do not exhaust me

        
        */}

    </div>
    {/* -------------------------------- */}
  <div className="carousel-lines">
      <p className="carousel-line-title">{T2title}</p>
        <p className="L1">The moon swings between hope and despair</p>
        <p className="L2">The stars shine everywhere</p>
        <p className="L3">Sometimes nowhere.</p>
        {/* <p className="L4">All without a sound.</p>
        <p className="L5"><br></br></p>
        <p className="L6">I don't know how they do it.</p>
        <p className="L7">What a strange way to die.</p>
        <p className="L8">{T2L8}</p> */}
    </div>
    {/* ---------------------------------------- */}
    <div className="carousel-lines">
      <p className="carousel-line-title">{T3title}</p>
        <p className="L1">{T3L1}</p>
        <p className="L2">{T3L2}</p>
        <p className="L3">{T3L3}</p>
        <p className="L4"><br></br></p>
        <p className="L5">{T3L5}</p>
        <p className="L6">{T3L6}</p>
        <p className="L7">{T3L7}</p>
        <p className="L8">{T3L8}</p>
    </div>
 </Carousel> 
</div>
    {/* <Things/> */}

</div>
    )
}
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import blog_image1 from "../assets/IMG_6658.jpg";
import blog_image2 from "../assets/cafe.jpg";
import blog_image3 from "../assets/apap.jpg";
import blog_image4 from "../assets/oceans.jpg";
import blog_image5 from "../assets/steak.jpg";
import blog_image6 from "../assets/hay.jpg";
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"
import BlogCarousel3 from "../components/BlogCarousel3.js"
import Footer from "../archive/Footer"

import BlogList from "../blogs/BlogList"




import blog_image9 from "../assets/pensacola.jpg";

export default function Animals() {
    return (
        <>
        <div className="blog-page">
           <BlogList/>
                <div className="blog-page-title">
                    <h3>The Animals</h3>
                </div>
                <div className="blog-page-date">
                    <p>08 Sep 2023 <i> - 8 minute read</i></p>
                </div>
                <div className="blog-page-quote">
                    <p><i>"They are revving their engines for you"</i></p>
                    <p><i>- Dr. Ayana Johnson</i></p>
                </div>
                <div className="blog-page-images">
                    <img src={blog_image1} 
                    style={{aspectRatio: 4032/3024}} 
                    width= '550px' 
                    className="blog-page-images-theImage🐵"
                
                    />
                    <p className="figures">
                        Figure 1: Pensacola</p>
                </div>

            

                <div className="blog-page-my-words">
                    <p><i>[I spent the last couple weeks of Spring '23 with my siblings. Ever since, this blog as been on my mind. It is difficult to write about them. I periodically make revisions.]</i></p>
                    <p>
                    Lately, I have been studying minds that often remind me of Keepie&#x23E4; people with relentless energy and a righteous attitude to change the world. In an interview with Sam, there was a conjecture about Nick Crocker, who wrote a blog called "The Elephants," or a group of powerful doers in his life.
                   </p>
                   <p> A few years ago, I believe I did meet a version of people who I would consider Elephants&#x23E4; "harmless, great things." However, unlike Nick's Elephants, they were not people who have rocked me to my core&mdash; just a rather sweet air :] I miss y'all.
                    </p>
                    <p>In retrospect, I did quickly realize that I do have access to a similar planetary source of power.</p>
                    <p> 
                    For the past 19 years, I've been part of an interesting group of people who I consider The Animals at what they do. To some, they are the Tran Clan and they serve as my source of fire and help change perspectives about the world I see.
                    </p>
                    <p>
                        Few know about this group of people and even fewer can recall the members' names. I'm one of the rare bunch that gets to call it my family. 
                        {/* They are my foolish fire. My source of fuel when the world is heavy and the energy I crave to change the world. */}
                    </p>
                        <p>At a graduation ceremony for Peppermint, an accidental set of words traced my mind and enchantingly, my brother sensed it too and casted them once more:</p>
                    </div>
                    <div className="blog-page-quote">"they are revving their engines for you"</div>
                    
                    
                       
              
                <div className="blog-page-my-words">
                    <p>There are a lot of people that dream of changing the world. There is a large benefit to belonging to a tribe. A family happens to be an interesting special case.</p>
                    <h3>The Family</h3>
        
                    <p><i>A king-sized mattress can fit 2 adults and a child. We needed 5 of those. We only had 4.</i></p>                    
                     
    
                    <p>For me, growing up and going to school, contained just as much joy in rediscovering the artifacts of their lives as it did trying new things for myself.
                        I tried comparing which has contributed more to my growth function and the former was a stronger source of fire.
                    </p>
                    <p>I was thinking about why this was the case and if there are other families I know that could help me answer it. (much love to the Alsheikh family). 
                        If I had to generalize this phenomenon to <i>something</i> 
                    it would probably be <i>history.</i> Humans are geared to become storytellers. Belonging to a family makes it easier, especially if you consider it to be one of a kind.</p>
                    <p>I find that people who believe they are building a story seem to get a lot more done. 
                        They are usually accompanied by a strong source of self conviction, think a lot about what they are doing and if it is either good for themsleves or for the world.
                     
                    </p>
                    <p>Belonging to a tribe helps produce an intense focus on figuring out the most important tasks of the year. Ultimately, the tribe fears letting each other down more than they fear letting their own self down.</p>
                    {/* <p>There is a visceral liminal energy to this system. There is a constant confrontation between the future and the present, leveraging your history to do so.
                        I think that people who belong to a tribe have an intense focus on figuring out the most imporant task of the year. Ultimately, they fear letting each other down more than they fear letting their own self down.
                    </p>  */}
                    <p>Work on something bigger than yourself.</p>
                    <h4>Time</h4> 
                </div>
                    <div className="blog-page-quote"><p><i>The most two powerful warriors are patience and time" - Tolstoy</i></p></div>
                <div className="blog-page-my-words">
                    <p>As a family, we learned this the hard way. For a bulk of our lives, we spent all of our time together. It was dreadful. Only recently have we been able to showcase this power.</p>
                    <p>Find The Animals who you would share breaking point moments, but not people you would spend your best productive time.</p>
                    <p>The game is to love the world, which contains over 8 billion people and very interesting problems. Divide & Conquer.</p>
                    <p>Just do something&mdash; your homework is to have something to say at Thanksgiving.</p>
                </div>
                <div className="blog-page-my-words">
                    <h3>The Home</h3>
                    <p>Homes are spaceships.</p>
                    <p>They don't make any sense, but it'll show you how to get through anything.</p>

                    <p>Every first step is generated in the home.</p>
                    <p>There is no greater generation of stories than the home. This is where you can build a unique picture of not only what it means to share a blood and a history,
                         but also paint qualities about who you are and who you want to be.</p>
                    <p>In many ways, we hated our home. Many of our decisions were built on spending less time in it. However, because of it, we have prized empathy as our core weapon.</p>
                </div>
                <div className="blog-page-quote">
                    <p><i>"Asgard is not a place. Never was"</i></p>
                    <p><i>"Asgard is where our people stand."</i></p>
                    <p><i>- Odin</i></p>
                </div>
                <div className="blog-page-my-words">
                    <p>The Home is not a place. It is a rendezvous for your energy. It serves as a microcosm of the world you want to make</p>
                    <ul><li>- resilient</li><li>- innovative</li><li>- hearty</li></ul>
                    <p>Most parts of it will be scary, but get through it, and it will be your secret weapon. Figure it out.</p>
                    <p>It is important to have private convsersations with each other about the fears & doubts of your world. 
                        It may be a vulnerable moment for you, but in underrated ways, it will change your life.</p>
                    <p>The idea is to only spend your time in the home sharing. You learn a lot in week 1. You start to have trouble in week 2. 
                        Tinker with these moments to share very strong opinions about your world. Those are the ones that matter the most.</p>
                    <p>There will usually be one word or phrase that charms your world.</p>
                    <p>Here are some oldies:</p>
                    <p>
                        <li><i>"take it easy"</i></li>
                        <li><i>"it's all up to you"</i></li>
                    </p>
                    <p><i>*remember to change the words a bit before you copy it and turn it in.</i></p>
                    <h4>Working :: Doing</h4>

                    <p>For a long time, we wrapped ourselves in a very small world, only thinking of ways to "work." <b>Working</b> is much different than <b>Doing</b> something.</p>
                    {/* <p>When you are in the zone and someone requests your attention would you rather say:</p> */}
                    {/* <ul><li>"one sec- i'm working"</li><li><i>or</i></li><li>"one sec- i'm doing something"</li></ul> */}
                    <p>The things you work on are naturally boring to the outsider.</p>
                    <p>
                        <li>Working (Terkel 1974)</li>
                        <li>Working (Obama 2023)</li>
                    </p>
                    <p>The things you <i>do</i> invite curiosity or respect.</p>
                    <p>You ask my siblings what they work on and they'll throw a disseration at you. What they are doing? suddenly everyone is building rockets, saving lives, getting fired, or getting married.</p>
                    <p> More stories are shared through the act of doing than the act of working. Humans do. 
                    Machines work. It's okay to be both, just remember your homework.</p>
                    
                    <p>Rev your engines for each other. Revive The Animals. The world highly favors the authentic.</p>

                </div>
                <div className="blog-page-my-words">
                    <h3>The World</h3>
                    <p>For a family of our magnitude, it may sound strange that most of the Tran Clan are terrible introverts.</p>
                    <h4>To the introverts:</h4>
                    <p>A part of you still loves people. Leverage that passion to do some really cool things and remember&mdash; you can always turn in the assignment late for partial credit (preferably by Christmas).</p>
                    <p>The Home should not be big enough for The Family. It is not a "living space." The idea is to force The Family into The World.</p>
                    <p>"Explore a lot of things, try a lot of things as cheaply and quickly as possible" - Sam</p>
                    <p>At heart, we are all problem solvers. Use these 11 months to discover and completely obsess over something _important_.</p>
                    <p>Enter the world. Step out of your comfort zone: take risks, meet smart people, and try something new. The most interesting problems to tackle are ones that seem counterintuitive, but make a lot of sense.</p>
                   <h4>Raison D'être</h4>
                    <p>The world is a special place. Exploring it is unnatural and all consuming. It will take over your life. Most days, you will forget you are human. It never gets any easier. The nature of the problems either change or get harder. Follow your instincts. 
                    </p>
                    <p>"Just Learn" - PG</p>
                    </div>
                <div className="blog-page-my-words">
                    <h3>L'Envoi</h3>
                    <p>If you want to change the world, there is great benefit to hanging around The Animals&mdash; James Bonds who are unflappable and tough as nails. They get stuff done.</p>
                    <p>A family isn't a people you would trade your time. It's a people you would spend all your lifetimes. I highly reccomend it. 5 stars.</p>
                </div>
                {/* <div className="blog-page-quote">
                    <p><i>"[The world is] revving their engines for you"</i></p>
                </div> */}
                <div className="blog-page-my-words">
                    Love, Player 14
                </div>
                <div className="blog-page-my-words">
                    <h3>Bonus Credits:</h3>
                    <p>Some moments together in Pensacola:</p>
                </div>
               
                <BlogCarousel1/>
                <div className="blog-page-my-words">
                    <p>Other moments captured in Vermont:</p>
                </div>
                <BlogCarousel3/>
                <BlogCarousel2/>
                
        
        
                <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>

                
            <div>

            </div>
            <Footer/>
        </div>
        </>
    )
}
import { BrowserRouter, Route, Routes, Outlet, Link } from 'react-router-dom';
import blog_image1 from "../assets/IMG_6658.jpg";
import blog_image2 from "../assets/cafe.jpg";
import blog_image3 from "../assets/apap.jpg";
import blog_image4 from "../assets/oceans.jpg";
import blog_image5 from "../assets/steak.jpg";
import blog_image6 from "../assets/hay.jpg";
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"
import BlogCarousel3 from "../components/BlogCarousel3.js"
import Footer from "../archive/Footer"

// mysql test
import ItemTest from "../components/Item.js"


// week 1
// proposal 1
import quadtree from "../assets/ProjectDM/570px-Quad_tree_bitmap.svg.png"

// proposal 2
import house_1 from "../assets/ProjectDM/hereditary-house-miniature-2.png"
import house_2 from "../assets/ProjectDM/hereditary-miniatures-widescreen.jpg"

// proposal 3
import BlogList from "./BlogList"
import Inkscape from "../assets/ProjectDM/inkscape.png"

// final project
import dreamMachine from "../assets/ProjectDM/dreamMachine.png"
// dash &#x23E4;






import blog_image9 from "../assets/pensacola.jpg";

export default function DreamMachine() {
    return (
        <>
            <div className="blog-page">
                <BlogList />

                <div className="upvote-and-title">
                    {/* <ItemTest itemId={3}/> */}
                    <h2>Dream Machine</h2>
                    <date><i>- 4 minute read</i></date>
                </div>

                {/* <div className="blog-page-title">
                    
                    <h3>Dream Machine</h3>
                </div>


                <div className="blog-page-date">
                    <p>Spring 2024<i> - x minute read</i></p>
                </div> */}

                <div className="blog-page-quote">
                    <p>"the hardest problem we solve on a daily basis is understanding other people" - nancy kanwisher (theory of mind & mentalizing)</p>
                </div>
                {/* <div className="blog-page-quote">
                    <p>"being deeply in love with life and existence and interactions with human beings&#x23E4; that's the main fear." - winston duke</p>
                </div> */}

                <div className="blog-page-my-words">
                    <h4>Art & The Global Citizen</h4>
                </div>
                <div className="blog-page-my-words">
                    <p>I care about art. Art can be useful. I am taking this class to learn more about what 21st century humans do every day and how they feel about what they do.</p>
                    <p>This page tracks the progress of my final project.</p>
                </div>

                <div className="blog-page-quote">
                    <h4>week 1: brainstorming</h4>
                </div>
                {/* <div className = "blog-page-my-words">
                       
                        <li>digital quilts</li>
                        <li>small worlds</li>
                        <li>dream machine</li>
                </div> */}
                <div className="blog-page-my-words">
                    <p><b>proposal 1:</b> digital quilts</p>
                    <div className="blog-carousel-images">
                        <img src={quadtree} className="blog-page-images-ProjectDM" />
                        <p className="figures">Figure 1: <a href="https://en.wikipedia.org/wiki/Quadtree" target="_blank" rel="noopener noreferrer">quadtree</a> (from wikipedia)</p>
                    </div>

                    <p>Kylie used this algorithm to stich images of chladni plate harmonics. I have some resources to recreate her experiment, but what's the fun in that?
                        Some computer vision could help me obtain different flavors of images, but what images would I use? I couldn't think of any, so I moved on to proposal 2.
                    </p>
                    <div></div>


                </div>

                <div className="blog-page-my-words">
                    <p><b>proposal 2:</b> small worlds</p>



                    <div className="blog-carousel-images" style={{ display: "grid", gridTemplateColumns: "auto", gridGap: "0px", justifyContent: "center" }}>
                        <img src={house_1} style={{ maxWidth: "600px" }}></img>
                        <img src={house_2} style={{ width: "600px" }}></img>
                        <p className="figures">Figure 2: <a href="https://www.stanwinstonschool.com/blog/its-hereditary-take-look-inside-dark-and-twisted-world-oscar-nominated-toni-collette-and-gabriel-byrne-starring-hereditary" target="_blank" rel="noopener noreferrer">The Making of Hereditary</a></p>
                    </div>
                    <p>
                        I recently watched Ari Aster's "Hereditary", an A24 Production. I like horror films, so I pay close attention
                        to the craftmanship behind the scenes. I also like small things, which makes this film special. It features miniature homes.
                    </p>
                    <p>This idea is too hard. Let's try something else.</p>
                </div>

                < div className="blog-page-my-words">
                    <p><b>proposal 3:</b> dream machine</p>

                    <p>this is the one.</p>
                    <p>I was actually with Keepie in DC when I saw it. I'm 95% sure he saw it too.</p>

                </div>
                <div className="blog-page-quote">
                    <h4>week 2: design & materials</h4>
                </div>
                <div className="blog-page-my-words">
                    <p>In essence, I have to design three major functions:</p>
                    <ul>
                        <li>1. Cylinder</li>
                        <li>2. How to rotate it</li>
                        <li>3. Light Source</li>
                    </ul>
                    <p>There is a laser cutter on campus I want to use. 24/7 access would be nice, but it requires a subscription and an orientation. My task is far too small. For now, I am able to submit orders. I'm thinking of using cardstock or acetate sheets for the cylinder, but first should identify shapes to cut.</p>
                    <p>I have an ambitious idea for a rotating mechanism. Still thinking about the light source.</p>
                </div>

                {/* week 3 */}

                <div className="blog-page-quote">
                    <h4>week 3: to spin</h4>

                </div>

                <div className="blog-page-my-words">
                    <p>This week, I deicded to meet with Bethany Taylor to talk about my proposals and my direction. I have a lead on a record player to act as a rotator, so engineering the crank shaft idea disappeared.</p>
                    <p>Questions I'll think about:</p>
                    <ul>
                        <li>- What parts are going well?</li>
                        <li>- Not going well?</li>
                        <li>- Things to work on in parallel?</li>
                        <li>- in series?</li>
                        <li>- are you screwed?</li>
                    </ul>
                    <p>I have access to 11" x 14" sheets of acetate. Using some 2πr magic, I know that the maximum diameter I can achieve with a 14" sheet is about 4.5 inches. This is a template made in Inkscape (free), which will be vectorized to be read by the laser cutter.</p>

                    <div className="blog-carousel-images">
                        <img src={Inkscape} className="" />
                        <p className="figures">Figure 3: stencils created in Inkscape </p>
                    </div>
                    <p>Time to brainstorm designs and submit the job proposal to the Fab Lab for review.</p>
                </div>

                <div className="blog-page-quote">
                    <h4>week 4: </h4>
                </div>
                <div className="blog-page-quote">
                    <h4>week 5: </h4>
                </div>
                <div className="blog-page-quote">
                    <h4>week 6: Final Product </h4>
                </div>

                <div className='blog-page-my-words'>
                    <div className="blog-carousel-images">
                        <img src={dreamMachine} style={{ width: "500px" }}></img>
                        <p className="figures">Figure 4: a dream machine </p>
                    </div>

                    <p>Picked up record player. Created stencil using cardstock from CVS with a box cutter. Used phone as light source. Also used voicememo to recite a poem I like.</p>


                </div>

                <div className="blog-page-quote">
                    <h4>References: </h4>
                </div>
                <div className="blog-page-my-words">
                    <ul>
                        <li><a href="https://en.wikipedia.org/wiki/Dreamachine" target="_blank" rel="noopener noreferrer"> Dreamachine (Wikipedia)</a></li>
                        <li>Working (Terkel 1974)</li>
                    </ul>

                </div>
                <div className="blog-page-the-end">
                    <p><i>C'est Tout</i></p>
                </div>
                <Footer />
            </div>
        </>
    )
}

import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import wamblyImage from "../assets/edgedetect()"
import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style
import BlogCarousel1 from "../components/BlogCarousel1.js"
import BlogCarousel2 from "../components/BlogCarousel2.js"
import Footer from "../archive/Footer"
import Barbie from "../assets/Barbie.jpg"
import Whale from "../assets/Whale.jpg"
import Person2Person from "../assets/Person2Person.jpg"
import AlwaysBeMyMaybe from "../assets/AlwaysBeMyMaybe.jpg"
import SmallSlowButSteady from "../assets/SmallSlowButSteady.jpg"
import Mid90s from "../assets/Mid90s.jpg"

import BlogList from "../blogs/BlogList"







export default function Travels() {
    return (
        <>
        <div className="blog-page">
            <BlogList/>
                <div className="blog-page-title">
                    <h3>Travels</h3>
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>
                <div className="blog-page-quote">
                    <p><i>"being on a magic carpet to watch America unfold."</i></p>
                    <p><i>- Nat Read</i></p>
                </div>


                {/* ------------------------------------------------------ */}
                <div className="blog-page-title">
                    <h4>Places & Stations:</h4>
                </div>
                <div className="blog-page-my-words">
                    <ul>
                    <li><b>&#x25CB; Buffalo, Niagara Falls - New York - Ontario</b></li>

                    </ul>
                </div>

                <div className="blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Washington D.C.</b></li>
                        <li>- Metro Station</li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Boston, Cambridge - Massachusetts </b></li>
                        <li>- T</li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Seattle, Sultan - Washington</b></li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Vancouver</b></li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Atlanta, Duluth, Savannah - Georgia</b></li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Dallas, Carrollton - Texas</b></li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Middlebury, Burlington - Vermont</b></li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Boulder, Denver - Colorado</b></li>
                        <li>Downtown Boulder Station:</li>
                        <li>- Bolt, 205, Bound</li>
                        <li>Denver Union Station:</li>
                        <li>- AB1, FF1</li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; San Diego, Visalia, Los Angeles - California</b></li>
                        <li>Kings Canyon National Park</li>
                        <li>Santa Monica</li>
                    </ul>
                </div>
                <div className= "blog-page-my-words">
                    <ul>
                        <li><b>&#x25CB; Gatlinburg - Tennessee</b></li>
                        <li>Smokey Mountains National Park</li>
                    </ul>
                </div>
            <div className="blog-page-the-end">
            <p><i>C'est Tout</i></p>
                </div>
               
               
                </div>
                

                

        <Footer/>
        </>
    )
}
import { BrowserRouter, Route, Routes, Outlet, Link} from 'react-router-dom';
import homeBlogFooter from "./homeBlogFooter"
import Footer from "./Footer"
import BlogList from "../blogs/BlogList"

export default function Blog() {
    return (
        <>
        <div className="blog-page">
            <BlogList/>

        
            <Footer/>
        </div>
        </>
    )
}
import Carousel from "react-multi-carousel"
import {Container, Col, Row} from "react-bootstrap";
import TestCarousel from '../components/TestCarousel';
import React, {useState} from 'react';




function Quotes() {
    return (
        <>
    <div 
    className="archive-section">
         <div className="archive-section-title-container">
            <h2 className="archive-section-title-container-title"><u>Quotes</u></h2>
            {/* <p className="archive-section-title-container-subtext">some words.</p> */}
          </div>
          {/* <div className="word-list-container">
            <ul>
                <li>
                <p className="archive-section-quote"><i>"That's only half of who I am"</i></p>
                <author className="archive-section-author">- Kiara, Lion King II</author>
                </li>
            </ul>
          </div> */}
          <div className="word-list-container">
            <ul>
                <li>
                    <p className="archive-section-quote"><i>"Emergency Exit &#8213; Break Glass"</i></p>
                    <author className="archive-section-author"><i>- subway window</i></author>
                </li>
            </ul>
          </div>
          <div className="word-list-container">
            <ul>
                <li>
                    <p className="archive-section-quote"><i>"Do not touch screen"</i></p>
                    <author className="archive-section-author"><i>- modern television</i></author>
                </li>
            </ul>
          </div>
          <div className="word-list-container">
            <ul>
                <li>
                    <p className="archive-section-quote"><i>"Jump to present"</i></p>
                    <author className="archive-section-author"><i>- discord</i></author>
                </li>
            </ul>
          </div>
          <div className="word-list-container">
            <ul>
                <li>
                <p className="archive-section-quote"><i>"Please, stand clear."</i></p>
                <author className="archive-section-author">- elevator</author>
                </li>
            </ul>
          </div>
    </div>
        </>
    )
}

export default Quotes;



import React, { useState, useEffect } from 'react';
import '../App.css'; // Import your main CSS file

const LD = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const preferDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setIsDarkMode(preferDarkMode);
  }, []);

  const toggleMode = () => {
    setIsDarkMode(prevMode => !prevMode);
    localStorage.setItem('isDarkMode', !isDarkMode);
  };

  return (
    <div className={isDarkMode ? 'dark-mode blog-page-my-words' : 'light-mode blog-page-my-words'}>
      <button onClick={toggleMode}>Toggle Mode</button>
      {/* Your website content here */}
      <p>hi</p>
    </div>
  );
};

export default LD;


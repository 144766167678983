import Carousel from "react-multi-carousel"
import {Container, Col, Row} from "react-bootstrap";
import TestCarousel from '../components/TestCarousel';
import React, {useState} from 'react';
import GoodWillHunting from "../assets/GoodWillHunting.jpg";
import DeadPoetsSociety from "../assets/DeadPoetsSociety.jpg"
import ThreeIdiots from "../assets/3Idiots.jpg"
import TheSocialNetwork from "../assets/TheSocialNetwork.jpg"
import PastLives from "../assets/PastLives.jpg"




function Movies() {
    

    const [open, setOpen] = useState(false);
    const Menus1 = ['\u2605\u2605\u2605\u2606\u2606'];
    const Menus2 = [`"Truth like a blanket"`];


   

    
    // solution




    return (
        <>
    <div 
    className="archive-section">
         <div className="archive-section-title-container">
            <h2 className="archive-section-title-container-title"
            
            ><u>Movies</u></h2>
            <p className="archive-section-title-container-subtext"></p>
          </div>


          <div className="archive-list-container❤️">
            <div className ="great-next"><p><b>Great:</b></p></div>
                
                
            <div className ="movie-title-container">

                <div className = "left-side">
            <a href="https://www.youtube.com/watch?v=ViCfc3eIpv4" target="_blank" rel="noopener noreferrer">
            <img src={GoodWillHunting}style={{aspectRatio: 983/1500}} width= "80px"className="movie-images"/></a>
                </div>
                
                <div className= "right-side">
                    <p className=""><b>Good Will Hunting (1997)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"any port in a storm"</i></p>
                        <p><i>"i had to go see about a girl"</i></p>
                    </div>
                </div>
                   
  
            </div>
            </div>
             {/* ------------------------------------------------------------------------------ */}

             <div className="archive-list-container❤️">

             <div className ="movie-title-container">

                <div className = "left-side">

                    <a href="https://www.youtube.com/watch?v=gQU3EphIpMY" target="_blank" rel="noopener noreferrer">
                    <img src={DeadPoetsSociety}style={{aspectRatio: 1016/1500}} width= "80px"className="movie-images"/></a>

                    
                </div>
                <div className= "right-side">
                    <p className=""><b>Dead Poets Society (1989)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"truth like a blanket"</i></p>
                        <p><i>"what will your verse be?"</i></p>
                    </div>
        
                </div>
            </div>
            <div className = "bottom-side-movie-comments">
                        <p>robin williams i miss you</p>
                    </div>
            </div>
            {/* ------------------------------------------------------------------------------ */}
            <div className="archive-list-container❤️">

             <div className ="movie-title-container">

                <div className = "left-side">
                    
                    <a href="https://www.youtube.com/watch?v=DKzBmRRdPXo" target="_blank" rel="noopener noreferrer">
                    <img src={ThreeIdiots}style={{aspectRatio: 3030/4364}} width= "80px"className="movie-images"/></a>
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>3 Idiots (2009)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-notes">
                        <p><i>"define machine"</i></p>
                     
                    </div>
                </div>
            </div>
            </div>
            {/* ------------------------------------------------------------------------------ */}
            <div className="archive-list-container❤️">

             <div className ="movie-title-container">

                <div className = "left-side">
                    
                    <a href="https://www.youtube.com/watch?v=1SGg3QxYvS0" target="_blank" rel="noopener noreferrer">
                    <img src={TheSocialNetwork}style={{aspectRatio: 183/275}} width= "80px"className="movie-images"/></a>
                    
                </div>
                <div className= "right-side">
                    <p className=""><b>The Social Network (2010)</b></p>
                    <p className="right-side-movie-stars">&#9733;&#9733;&#9733;&#9733;</p>
                    <div className="right-side-movie-quotes">
                        <p><i>"false advertising"</i></p>
                        <p><i>"there's two of me"</i></p>
                    </div>
                </div>
            </div>
          </div>
    </div>
        </>
    )
}

export default Movies;




import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet, Link } from 'react-router-dom';

import "@fontsource/roboto-mono"; // Defaults to weight 400
import "@fontsource/roboto-mono/400.css"; // Specify weight
import "@fontsource/roboto-mono/400-italic.css"; // Specify weight and style

import Footer from "../archive/Footer"

// 2024
import ITonya from "../assets/2024/ITonya.jpg"
import NightSwim from "../assets/2024/NightSwim.jpg"
import DMMDI from "../assets/2024/TheConjuringDMMDI.jpg"
import JaneDoe from "../assets/2024/JaneDoe.jpg"
import Exorcist from "../assets/2024/TheExorcist.jpg"
import Interstellar from "../assets/Interstellar.jpg"



// Feb
import EvilDeadRise from "../assets/2024/EvilDeadRise.jpg"
import Insidious from "../assets/2024/Insidious.jpg"
import Smile from "../assets/2024/Smile.jpg"
import Babadook from "../assets/2024/Babadook.jpg"

import ToyStory3 from "../assets/ToyStory3.jpg";
import PastLives from "../assets/PastLives.jpg"
import DreamScenario from "../assets/DreamScenario.jpg"
import WallE from "../assets/walle.jpeg";
import TheJoyLuckClub from "../assets/2024/TheJoyLuckClub.jpg"
import AFewGoodMen from "../assets/2024/AFewGoodMen.jpg"




// Evil Dead Rise

// dash &#x23E4;

const movies_may = [
  {
    id: 2,
    title: 'A Few Good Men (1992)',
    image: AFewGoodMen,
    rating: null,
    link: null,
  },

  {
    id: 1,
    title: 'The Joy Luck Club (1993)',
    image: TheJoyLuckClub,
    rating: 3,
    link: null,
  },

]

const movies_apr = [
  {
    id: 1,
    title: 'Wall-E (2008)',
    image: WallE,
    rating: 5,
    link: "https://www.youtube.com/watch?v=0IPxIvbc_cs",
  },

]

const movies_feb = [

  {
    id: 6,
    title: 'Dream Scenario (2023)',
    image: DreamScenario,
    rating: 4,
    link: "https://www.youtube.com/watch?v=q3x9iUL-74w",
  },
  {
    id: 5,
    title: 'Past Lives (2023)',
    image: PastLives,
    rating: 4.5,
    link: "https://www.youtube.com/watch?v=VxcyuJuDmKI",
  },
  {
    id: 4,
    title: 'Toy Story 3 (2010)',
    image: ToyStory3,
    rating: 3,
    link: null,
  },

  {
    id: 3,
    title: 'The Babadook (2014)',
    image: Babadook,
    rating: 3,
    link: null,
  },
  {
    id: 2,
    title: 'Smile (2022)',
    image: Smile,
    rating: 2.5,
    link: null,
  },
  {
    id: 1,
    title: 'Evil Dead Rise (2023)',
    image: EvilDeadRise,
    rating: 2.5,
    link: null,
  },
  // Add more movies as needed
];



const movies_jan = [
  {
    id: 7,
    title: 'Interstellar (2014)',
    image: Interstellar,
    rating: 4.5,
    link: null,
  },
  {
    id: 6,
    title: 'Insidious (2010)',
    image: Insidious,
    rating: 3,
    link: null,
  },
  {
    id: 5,
    title: 'The Exorcist (1973)',
    image: Exorcist,
    rating: 2.5,
    link: null,
  },
  {
    id: 4,
    title: 'The Autopsy of Jane Doe (2016)',
    image: JaneDoe,
    rating: 3,
    link: null,
  }
  ,
  {


    id: 3,
    title: 'The Conjuring: The Devil Made Me Do It (2021)',
    image: DMMDI,
    rating: 4,
    link: null,
  },
  {
    id: 2,
    title: 'Night Swim (2024)',
    image: NightSwim,
    rating: 2,
    link: 'https://www.youtube.com/watch?v=YAzT_wOIjAU',
  },
  {
    id: 1,
    title: 'I, Tonya (2017)',
    image: ITonya,
    rating: 3,
    link: 'https://www.youtube.com/watch?v=YAzT_wOIjAU',
  },
  // Add more movies as needed
];




export default function Diary2024() {

  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleButtonClick = componentName => {
    setSelectedComponent(componentName);

  };

  useEffect(() => {
    const handleButtonClick = event => {
      const clickedButton = event.target;

      if (!clickedButton.closest('.seasons')) {
        return; // Clicked outside the .seasons div
      }

      const buttons = document.querySelectorAll('.seasons button');

      buttons.forEach(button => {
        if (button !== clickedButton) {
          button.classList.remove('active');
        }
      });

      clickedButton.classList.add('active');
    };

    document.addEventListener('click', handleButtonClick);

    return () => {
      document.removeEventListener('click', handleButtonClick);
    };
  }, []);


  const getStars = (rating) => {
    if (rating === null || rating === undefined) {
      return (
        <>
          {'☆'.repeat(5)}
        </>
      );
    }

    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    return (
      <>
        {'★'.repeat(fullStars)}
        {halfStar && '½'}
      </>
    );
  };

  return (
    <>
      <div className="blog-page">

        {/* <div className="blog-page-title">
                    <h3>Movies</h3>
                </div>
                <div className="blog-page-date">
                    <p></p>
                </div>
                */}


        {/* ------------------------------------------------------ */}
        <div className="blog-page-title">
          <h4>2024</h4>

        </div>
        <div className="blog-page-date">
          <p></p>
        </div>

        <div className="diary-label">
          <h5>may</h5>
        </div>

        <div className="archive-list-container❤️">
          {movies_may.map((movie) => (
            <div key={movie.id}>
              <div className="movie-title-container">
                <div className="left-side">

                  <a href={movie.link} target="_blank" rel="noopener noreferrer">
                    <img src={movie.image} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" alt={movie.title} />
                  </a>

                </div>
                <div className="right-side">
                  <p className=""><b>{movie.title}</b></p>
                  <p className="right-side-movie-stars">{getStars(movie.rating)}</p>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>

        <div className="diary-label">
          <h5>apr</h5>
        </div>

        <div className="archive-list-container❤️">
          {movies_apr.map((movie) => (
            <div key={movie.id}>
              <div className="movie-title-container">
                <div className="left-side">

                  <a href={movie.link} target="_blank" rel="noopener noreferrer">
                    <img src={movie.image} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" alt={movie.title} />
                  </a>

                </div>
                <div className="right-side">
                  <p className=""><b>{movie.title}</b></p>
                  <p className="right-side-movie-stars">{getStars(movie.rating)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="diary-label">
          <h5>mar</h5>
        </div>

        <div className="diary-label">
          <h5>feb</h5>
        </div>

        <div className="archive-list-container❤️">
          {movies_feb.map((movie) => (
            <div key={movie.id}>
              <div className="movie-title-container">
                <div className="left-side">

                  <a href={movie.link} target="_blank" rel="noopener noreferrer">
                    <img src={movie.image} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" alt={movie.title} />
                  </a>

                </div>
                <div className="right-side">
                  <p className=""><b>{movie.title}</b></p>
                  <p className="right-side-movie-stars">{getStars(movie.rating)}</p>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>

        <div className="diary-label">
          <h5>jan</h5>
        </div>
        <div className="archive-list-container❤️">
          {movies_jan.map((movie) => (
            <div key={movie.id}>
              <div className="movie-title-container">
                <div className="left-side">

                  <a href={movie.link} target="_blank" rel="noopener noreferrer">
                    <img src={movie.image} style={{ aspectRatio: 183 / 275 }} width="40px" className="movie-images" alt={movie.title} />
                  </a>

                </div>
                <div className="right-side">
                  <p className=""><b>{movie.title}</b></p>
                  <p className="right-side-movie-stars">{getStars(movie.rating)}</p>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>









      </div>
      <br></br>
      <wbr></wbr>
      <div className="blog-page-the-end">
        <p><i>C'est Tout</i></p>
      </div>
      <Footer />
    </>
  )
}
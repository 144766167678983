import Carousel from "react-multi-carousel"
import {Container, Col, Row} from "react-bootstrap";
import TestCarousel from '../components/TestCarousel';
import React, {useState} from 'react';




function Things() {
    return (
        <>
    <div 
    className="archive-section">
         <div className="archive-section-title-container">
            <h2 className="archive-section-title-container-title"><u>Things</u></h2>
            {/* <p className="archive-section-title-container-subtext">some things.</p> */}
          </div>
          <div className="archive-list-container">
            <ul>
                <li>
                    <a href="https://www.leuchtturm1917.us/notebooks/">LEUCHTTURM1917 (Medium A5 / Bullet)</a>
                </li>
                <li>
                    <a href="https://usa.yamaha.com/products/musical_instruments/pianos/p_series/p-125/index.html">Yamaha P-125 (digital piano)</a>

                </li>
                {/* <li>
                    <a href="https://www.kleintools.com/catalog/multi-bit-screwdrivers/multi-bit-electronics-screwdriver-4-1-phillips-slotted-bits">Multi-Bit, 4-in-1 Screwdriver</a>

                </li> */}
                <li>
                    <a href="https://www.level8cases.com/search?q=matte">Elegance Matte Series Collection (20")</a>

                </li>
            </ul>
          </div>
    </div>
        </>
    )
}

export default Things;



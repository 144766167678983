import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes, Outlet, useHistory} from 'react-router-dom';


import Home from './components/Home';
import Archive from './components/Archive';
import NavBar from './components/NavBar';
import "./App.css"
import Header from './components/Header';
import Footer from './components/homeBlogFooter';
import { hasPointerEvents } from '@testing-library/user-event/dist/utils';
import Router from './components/Router';
import TestCarousel from './components/TestCarousel';
import {Helmet} from "react-helmet";



function App () {
  

  return(
   
    <>
  <div>
{/*  
      <meta charSet="utf-8" />
      <title>Andy Tran</title>
      <link rel="canonical" href="http://mysite.com/example" /> */}
  {/* <Router/> */}
  {/* <NavBar/> */}
 {/* <Header/> */}
 <Router/>

   </div>
   </>
  )
}

export default App





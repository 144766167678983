import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useState} from 'react';
import ReactDOM from 'react-dom/client';
// pensacola
import blog_image1 from "../assets/IMG_6658.jpg"; // beachhouse-- flat
import blog_image2 from"../assets/park.jpg"; // the park -- flat
import blog_image3 from "../assets/starbs.jpg"; // starbucks -- flat

// vermont
import blog_imageChairs from "../assets/chairs.jpg" // Chairs -- 4032/3024
import blog_imagePeppermint from "../assets/peppermint.jpg" // Pepper & the boyz -- 4032/3024 flat
import blog_imageCafe from "../assets/cafe.jpg"; // Le Marche Cafe -- flat
import blog_imageHay from "../assets/hay.jpg"; // quick. no one is watching. -- flat
import blog_image6 from "../assets/oceans.jpg"; // oceans and engines 1/1
import blog_image7 from "../assets/steak.jpg"; // the last supper 1/1
import blog_image8 from "../assets/apap.jpg"; // APAP 1/1

  
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export default function BlogCarousel3(props) {

    return (
        <div className="something-big-blog-c1">
         
          

          <div>
        <Carousel
arrows={true}
        swipeable={true}
            draggable={true}
            showDots={true}
        centerMode={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={false}
//   autoPlay={props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition="transform 1000ms ease-in-out"
  transitionDuration={1000}
  containerClass="carousel-container"
//   removeArrowOnDeviceType={["tablet", "mobile"]}
    deviceType={props.deviceType}

  dotListClass="react-multi-carousel-dot-list"
  itemClass="carousel-item-padding-0-px"
  className="blog-giant-carousel3"
>
 
    <div className="blog-carousel-images">
        <img src={blog_imageCafe}style={{aspectRatio: 780/585}} width= "500px"className="blog-page-images-theImage👻"/>
        <p className="figures">Figure 5: Le Marche Cafe <s>Authentic Beignets</s></p>
    </div>
        {/* image 4 */}
    <div className="blog-carousel-images">
        <img src={blog_imageHay} width= "500px" style={{aspectRatio: 4032/3024}} className="blog-page-images-theImage👻"/>
        <p className="figures">Figure 6: Quick. No one is watching.</p>
    </div>
    <div className="blog-carousel-images">
        <img src={blog_imageChairs} width= "500px" style={{aspectRatio: 4032/3024}} className="blog-page-images-theImage👻"/>
        <p className="figures">Figure 7: some chairs.</p>
    </div>
    
    <div className="blog-carousel-images">
        <img src={blog_imagePeppermint} width= "500px" style={{aspectRatio: 4032/3024}} className="blog-page-images-theImage👻"/>
        <p className="figures">Figure 8: it's not a weapon.</p>
    </div>
    
    
</Carousel>
</div>

</div>
    )
}
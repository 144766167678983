import Carousel from "react-multi-carousel"
import {Container, Col, Row} from "react-bootstrap";
import TestCarousel from '../components/TestCarousel';
import React, {useState} from 'react';




function Poetry() {
    return (
        <>
    <div 
    className="archive-section">
         <div className="archive-section-title-container">
            <h2 className="archive-section-title-container-title"><u>Poetry</u></h2>
            {/* <p className="archive-section-title-container-subtext">some words.</p> */}
          </div>
          {/* <div className="set3-buttons">
              <button className="poetry-button" onClick={oblivionClick}>Oblivion</button>
              <button className="poetry-button" onClick={pavaneClick}>Pavane</button>
              <button className="poetry-button" onClick={wamblyClick}>Wambly</button>
        </div> */}
          <TestCarousel/>
    </div>
        </>
    )
}

export default Poetry;


